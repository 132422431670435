<template>
	<a
		class="rpb_hotelbox"
		:class="boxClass"
		:href="hotel.Url"
		:title="hotel.Name"
		@mousedown="fireSelectPromotionEvent($event)"
	>
		<div class="rpb_hotelbox__image">
			<img
				:alt="hotel.Name"
				:data-srcset="hotel.Image.s + ' 330w, ' + hotel.Image.m + ' 513w'"
				:data-src="hotel.Image.m"
				src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
				class="rpb_hotelbox__image rpb_lazy"
			/>
			<div
				tabindex="0"
				class="rpb_hotelbox__bookmark"
				:class="bookmarked ? 'rpb_hotelbox__bookmark--active' : ''"
				:data-iff="hotel.HotelIffCode"
				:data-ga-name="hotel.Name"
				:data-ga-category="hotel.CountryName + ' / ' + hotel.RegionName + ' / ' + hotel.CityName"
				@click.prevent.stop="bookmark()"
				@mousedown.prevent.stop
			>
				<svg
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					width="32"
					height="32"
					viewBox="0 0 27 32"
				>
					<path d="M13.813 29.063l-0.375-0.313-0.438-0.375-0.625-0.438-0.563-0.5-0.5-0.438-0.875-0.75-0.563-0.438-0.688-0.625-0.625-0.563-2.125-2.125-0.625-0.688-1.25-1.25-0.688-0.813-0.563-0.75-0.688-0.75-0.438-0.688-0.563-0.813-0.563-0.875-0.438-0.938-0.313-0.938-0.188-0.938-0.125-0.875v-1.5l0.063-0.75 0.25-1.125 0.25-0.75 0.25-0.563 0.25-0.625 0.438-0.75 0.688-0.875 0.688-0.688 1.125-0.813 0.875-0.438 1-0.313 0.625-0.125h0.438l1.438 0.063 1.375 0.375 0.875 0.5 0.75 0.5 0.625 0.563 0.75 0.75 0.5 0.688 0.25 0.563h0.125l0.125-0.25 0.125-0.313 0.25-0.313 0.375-0.563 0.375-0.438 0.438-0.438 0.563-0.5 0.75-0.5 0.75-0.313 0.875-0.375 1.188-0.188h0.688l1.125 0.125 1.125 0.375 0.813 0.438 1.188 0.813 0.75 0.875 0.813 1.125 0.563 1.125 0.5 1.438 0.25 1.5-0.125 2.063-0.063 0.75-0.313 1-0.25 0.688-0.938 1.688-0.688 1.063-0.813 1.063-0.75 0.875-0.938 1.188-0.813 0.938-0.875 0.875-1.313 1.375-0.75 0.75-1 0.938-1.438 1.375-1.5 1.25z" />
				</svg>
			</div>
		</div>
		<div class="rpb_hotelbox__subline">
			<span class="rpb_hotelbox__region">
				<span v-if="isRegionOrCity">{{ hotel.CityName }}</span>
				<span v-else>{{ hotel.RegionName }}</span>
			</span>
			<span
				v-if="hotel.MeanRatingOverall >= 4.2"
				class="rpb_hotelbox__rating"
			>
				{{ ratingText(hotel.MeanRatingOverall) }} &ndash; {{ hotel.MeanRatingOverall }}/{{ bestRating }}
			</span>
		</div>
		<h3
			class="rpb_hotelbox__hotel-name"
			:title="hotel.Name"
		>
			{{ hotel.Name }}
		</h3>
		<HotelStars
			:show-suns="showSuns"
			:rating="hotel.Category"
			class="hotelrating--height"
		/>
		<ul
			v-if="!deal"
			class="rpb_hotelbox__text"
		>
			<li>{{ hotel.TravelDuration }} Tage</li>
			<li>{{ hotel.BoardType }}</li>
			<template v-if="hotel.DepartureAirportTown">
				<li>Flug ab {{ hotel.DepartureAirportTown }}</li>
			</template>
		</ul>
		<ul
			v-if="deal"
			class="rpb_hotelbox__text"
		>
			<li>{{ hotel.CountryName }}</li>
			<li>{{ hotel.BoardType }}</li>
			<li>{{ hotel.TravelDuration }} Tage</li>
			<li v-if="hotel.DepartureAirportTown">
				Flug ab {{ hotel.DepartureAirportTown }}
			</li>
			<li v-if="hotel.DepartureDay">Abflug: {{ convertDateHelper(hotel.DepartureDay, domain) }}</li>
		</ul>
		<div
			class="rpb_hotelbox__footer"
		>
			<div
				v-if="deal"
				class="rpb_hotelbox__specialoffer"
			>
				<template v-if="hotel.DealFactor && hotel.DealFactor < 100 && hotel.Discount && hotel.PriceAverage">
					<div class="rpb_hotelbox__specialoffer--discount">{{ hotel.Discount }}%</div>
					<del class="rpb_hotelbox__specialoffer--oldprice">{{ averagePriceFormatted }}</del>
				</template>

			</div>
			<div class="rpb_hotelbox__price">
				<Price
					:price="hotel.Price"
					:currency="currencySymbol"
				/>&nbsp;p.P.
			</div>
		</div>
	</a>
</template>

<script lang="ts" setup>
import {
	ratingText,
	convertDateHelper,
} from '@utils/utils';
import {
	addEntryToFavoriteCookie,
	removeEntryFromFavoriteCookie,
	isFavorite as isFavoriteCookie,
} from '@modules/bookmark';
import Price from '@lmt-rpb/Price/Price.vue';
import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import { HotelBoxData } from '@interfaces/components/hotelBoxData';
import { EventBus } from '@global-js/event-bus';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import PromotionAnalytics from '@/js/modules/analytics/PromotionAnalytics';
import {
	computed, onBeforeUnmount, onMounted, ref,
} from 'vue';
import { formatPrice } from '@/js/utils/priceUtils';

interface Props {
	deal?: boolean,
	hotel: HotelBoxData,
	currencySymbol: string,
	showSuns: boolean,
	domain?: string,
	slider: boolean,
	promotionName: string,
	bestRating?: number,
	index: number,
	hasPromotionAnalytics?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	deal: false,
	showSuns: false,
	domain: 'AT',
	slider: true,
	bestRating: 6,
	hasPromotionAnalytics: true
});

const bookmarked = ref(false);

const containsBodyClass = (bodyClass: string): boolean => document.body.classList.contains(bodyClass);

const isRegionOrCity = computed((): boolean => containsBodyClass('page_region') || containsBodyClass('page_city') || containsBodyClass('page_topic_topregion'));

const boxClass = computed(() => ({
	'rpb_hotelbox--deal-list': props.deal && !props.slider,
	'rpb_hotelbox--slider': props.slider,
}));

const averagePriceFormatted = computed((): string => (props.hotel.PriceAverage ? formatPrice(props.hotel.PriceAverage, props.currencySymbol) : ''));

const bookmark = (): void => {
	const items = BookmarkAnalytics.fromHotelBoxData(props.hotel, props.currencySymbol);
	if (!bookmarked.value) {
		addEntryToFavoriteCookie(items, props.hotel.Category);
	} else {
		removeEntryFromFavoriteCookie(items);
	}
};

const updateBookmark = (iffCode: number, favorite: boolean): void => {
	if (iffCode === props.hotel.HotelIffCode) {
		bookmarked.value = favorite;
	}
};

const checkForFavorites = async() => {
	bookmarked.value = await isFavoriteCookie(props.hotel.HotelIffCode);
};

const fireSelectPromotionEvent = (event: MouseEvent): void => {
	if (!props.hasPromotionAnalytics) {
		return;
	}
	const buttonType = event?.button;

	// Firing GA4 event for left and middle mouse button (works on desktop and mobile)
	if (buttonType === 0 || buttonType === 1) {
		const creativeName = props.hotel.DealFactor ? 'Restplätze' : 'Topseller';
		const promotionAnalytics = PromotionAnalytics.fromHotelBoxData(creativeName, props.promotionName, props.hotel, props.currencySymbol, props.index);
		promotionAnalytics.fireSelectPromotion();
	}
};

onMounted((): void => {
	checkForFavorites();
	EventBus.$on('bookmark:favoriteChange', updateBookmark);
});

onBeforeUnmount((): void => {
	EventBus.$off('bookmark:favoriteChange', updateBookmark);
});

</script>

<style lang="scss" scoped>
/* Mobile */
.rpb_hotelbox {
	display: inline-block;
	padding: 0 0 1.4rem;
	transition: box-shadow 0.25s ease;
	box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
	color: $color-text;
	font-size: 1.6rem;
	line-height: 1.7;
	text-decoration: none;
	text-align: left;
	border-bottom: 0.2rem solid transparent;
	position: relative;
	max-width: 46.8rem;
	border-radius: $border-radius-medium;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		box-shadow: -0.1rem 0.5rem 1rem $color-black-t35;
		outline: 0;
		color: inherit;
	}

	a,
	a:hover,
	a:active {
		color: $color-white;
		text-decoration: none;
		display: inline-block;
	}

	img {
		width: 100%;
		border-top-left-radius: $border-radius-medium;
		border-top-right-radius: $border-radius-medium;
	}

	&__image {
		font-size: 0;
		overflow: hidden;
		position: relative;

		&--placeholder_box {
			position: relative;
			background-color: $color-medium-gray;
			padding-bottom: 66.66%;
			height: 0;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	h3 {
		font-family: $font-family;
		color: $color-text;
		font-size: 2.2rem;
		font-weight: 600;
		line-height: 3.4rem;
		text-align: left;
		clear: both;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
		padding: 0 1.6rem;
	}

	p {
		margin: 0;
		padding: 0.5rem 0;
		font-size: 1.6rem;
	}

	.hotelrating--height {
		height: 1.4rem;
		margin-top: 0.4rem;
		margin-bottom: 1rem;
		text-align: left;
		padding: 0 1.6rem;
	}

	&__footer {
		display: flex;
		padding: 0 0.8rem;
		justify-content: flex-end;
		align-items: flex-end;
		gap: 1.2rem;
		margin-top: auto;
	}

	&__text {
		min-height: 6.2rem;
		list-style: none;
		padding: 0 1.6rem 0.8rem;
		font-size: 0;
		text-align: left;
		margin: 0;

		li {
			display: inline-block;
			font-size: 1.6rem;
			max-width: 28rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		li::after {
			content: "•";
			color: $color-primary-l1;
			display: inline-block;
			margin: 0 0.5rem;
		}

		li:last-child::after {
			content: "";
		}
	}

	&__specialoffer {
		color: $color-text;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 0.2rem;

		&--discount {
			font-size: 2rem;
			line-height: 1;
			font-weight: 700;
			position: relative;
			display: inline-block;
			padding: 0.2rem 1rem 0.2rem 0.5rem;
			overflow: hidden;
			color: $color-white;
			text-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
		}

		&--discount::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-secondary;
			-webkit-transform-origin: 100% 0;
			-ms-transform-origin: 100% 0;
			transform-origin: 100% 0;
			-webkit-transform: skew(-17deg);
			-ms-transform: skew(-17deg);
			transform: skew(-17deg);
			z-index: -1;
		}

		&--oldprice {
			padding: 0 0 0.1rem 0;
			font-size: 1.5rem;
			font-weight: 600;
			text-align: right;
			line-height: 2rem;
			color: $color-primary;
		}
	}

	&--deal-list {
		width: 100%;
		max-width: none;
	}

	&__price {
		display: flex;
		gap: 0.4rem;
		align-items: flex-end;
		flex-direction: row;
		justify-content: flex-end;
		color: $color-text;
		height: 3.6rem;
		line-height: 2.7rem;
		font-size: 1.4rem;
	}

	.rpb_price {
		font-size: 2.4rem;
		line-height: 3.4rem;
		text-align: left;
		display: block;

		:deep(.rpb_price__from-text) {
			font-size: 1.4rem;
			line-height: 2.7rem;
		}

		:deep(.rpb_price__amount) {
			color: $color-extra;
		}
	}
}

.rpb_hotelbox__bookmark {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 0 1rem 1rem;

	svg {
		margin-right: 1rem;
		margin-top: 1rem;
		fill: $color-white;
		fill: $color-white-t3;
		stroke: $color-white;
		stroke-width: 0.1rem;
		width: 3.6rem;
		height: 3.6rem;
		transition: fill 0.3s, stroke 0.3s, transform 0.3s;
	}

	&:focus {
		outline: 0;

		svg {
			transform: scale(1.1);
			fill: $color-white-t5;
		}
	}

	&--active {
		svg { /* stylelint-disable-line no-descending-specificity */
			fill: $color-extra;
			animation: rpb_hotelbox__hearbeat 0.25s 2;
		}

		&:hover svg {
			transform: scale(1);
		}

		&:focus svg {
			fill: $color-extra;
		}
	}
}

.rpb_hotelbox__bookmark:hover svg {
	transform: scale(1.1);
}

@keyframes rpb_hotelbox__hearbeat {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.rpb_hotelbox__region {
	font-size: 1.4rem;
	font-weight: 600;
	color: $color-text-light;
	flex: 1 1 100%;
	overflow: hidden;

	span {
		display: block;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 1rem;
	}
}

.rpb_hotelbox__rating {
	font-size: $font-small;
	color: $color-primary;
	font-weight: 700;
	white-space: nowrap;
	height: 2.8rem;
	display: flex;
}

.rpb_hotelbox__subline {
	border-bottom: 0.1rem solid $color-primary-l5;
	display: flex;
	align-items: baseline;
	padding: 0 1.6rem;
	height: 2.9rem;
}

.rpb_hotelbox--slider {
	max-width: 28.4rem;
	width: 28.4rem;
	display: inline-block;

	.rpb_hotelbox__text {
		white-space: normal;
		flex: 1 0 auto;
	}
}

@media (min-width: $breakpoint-mobilelarge) {
	.rpb_hotelbox__footer {
		padding: 0 1.6rem;
	}
}

/* Mobile landscape */
@media (min-width: $breakpoint-mobile) {
	.rpb_hotelbox__specialoffer .rpb_price {
		font-size: 2.4rem;
	}
}

/* Mobile landscape */
@media (min-width: $breakpoint-verysmall) {
	.rpb_hotelbox {
		font-size: 1.4rem;
	}

	.rpb_textbox,
	.rpb_hotelbox__text {
		li {
			max-width: 24rem;
		}
	}

	.rpb_hotelbox__specialoffer .rpb_price {
		font-size: 3rem;
	}
}

/* Tablet  Portrait */
@media (min-width: $breakpoint-small) {
	.rpb_hotelbox {
		&__specialoffer {
			&--oldprice {
				padding-left: 0;
			}
		}

		&__price {
			font-size: 1.8rem;
		}

		.rpb_price {
			:deep(.rpb_price__from-text) {
				font-size: 1.8rem;
			}

			:deep(.rpb_price__amount) {
				font-size: 2.8rem;
				line-height: 3.4rem;
			}
		}
	}
}

/* Tablet landscape */
@media (min-width: $breakpoint-medium) {
	.rpb_hotelbox {
		&__footer {
			gap: 1.6rem;
		}

		h3 {
			line-height: 1.7;
		}
	}
}

@media (min-width: $breakpoint-scroller) {
	.rpb_textbox {
		flex: 1 0 auto;
	}

	.rpb_hotelbox--slider {
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		white-space: nowrap;
		-webkit-tap-highlight-color: transparent;

		.rpb_hotelbox__rating {
			span {
				color: $color-text-light;
			}
		}

		.rpb_hotelbox__text {
			white-space: normal;
			line-height: 1.5;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.rpb_hotelbox {
		&__footer {
			align-items: baseline;
		}

		&__specialoffer {
			flex-direction: row;
			align-items: baseline;
			gap: 0.8rem;
		}

		.rpb_price {
			:deep(.rpb_price__amount) {
				font-size: 3rem;
			}
		}
	}
}

</style>
