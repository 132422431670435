<template>
	<div
		ref="root"
		class="map-offer-button"
		:class="{'map-offer-button--active' : isActive}"
		@mouseenter="mouseEnterHandler"
		@mouseleave="mouseLeaveButton($event)"
	>
		<button
			class="map-offer-button__button"
		>
			{{ formattedPrice }}
		</button>
		<Teleport
			to=".map-with-offers"
			:disabled="!isMobile"
		>
			<MapOfferBox
				:class="{'map-offer-button__hotelbox--hover-only': !buttonClicked, 'map-offer-button__hotelbox--active': isMobile && isActive}"
				class="map-offer-button__hotelbox"
				:item="item"
				:show-suns="showSuns"
				:domain="domain"
				:best-rating="bestRating"
				:rating="rating"
				:is-active="isActive"
				:is-hotel-page
				:should-navigate
				@MapOfferBox:LinkOpened="buttonClick"
				@MapOfferBox:Close="closeInfoWindow"
			/>
		</Teleport>
	</div>
</template>

<script lang="ts" setup>
import {
	computed, onMounted, ref,
} from 'vue';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import MapOfferBox from '@lmt-rpb/MapOfferBox/MapOfferBox.vue';
import { useStore } from '@/components/common/store';
import { formatPrice } from '@/js/utils/priceUtils';

interface Props {
	item: ItemType,
	showSuns: boolean,
	domain?: string,
	bestRating?: number,
	rating?: number,
	index: number
	map: google.maps.Map;
	defaultOpen?: boolean
	isHotelPage?: boolean
	shouldNavigate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	showSuns: false,
	domain: 'AT',
	bestRating: 6,
	rating: 2,
	defaultOpen: false
});

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);

const root = ref<HTMLElement>();

const marker = ref<google.maps.marker.AdvancedMarkerElement>();

const infowindow = ref<google.maps.InfoWindow>();

const isActive = ref(false);

const buttonClicked = ref(false);

const store = useStore();

const isMobile = computed(() => store.state.config.isMobile);

const mouseEnterHandler = () => {
	if (isMobile.value) return;
	setActive(true);
};

onMounted(() => {
	createMarker();
	if (props.defaultOpen) {
		setActive(true);
		buttonClicked.value = true;
	}
});

const emit = defineEmits(['MapOfferButton:Click']);

function closeInfoWindow() {
	buttonClicked.value = false;
	setActive(false);
}

function buttonClick() {
	emit('MapOfferButton:Click');
	setActive(true);
	buttonClicked.value = true;
}
function setActive(state: boolean) {
	if (state && !isActive.value) {
		openInfoWindow();
		isActive.value = state;
	} else if (!state && !buttonClicked.value) {
		infowindow.value?.close();
		isActive.value = state;
	}
}

function openInfoWindow() {
	infowindow.value?.open({
		anchor: marker.value,
		map: marker.value!.map,
	});
	if (isMobile.value) {
		// Comes from MapWithOffers
		const initialZoom = 7;
		const currentZoom = props.map.getZoom();
		// when the mobile browser toolbars are open this is needed to ensure that the marker is still in view
		const availableHeightRatio = (window.screen.height === window.innerHeight) ? 1 : (window.screen.height / window.innerHeight) * 1.5;
		const yOffset = -1 * ((availableHeightRatio * 0.25) ** Math.max(1, ((currentZoom ?? initialZoom) - initialZoom)));
		if (props.map.getRenderingType() !== 'UNINITIALIZED') {
			props.map.panTo({ lat: props.item.Location.Latitude + yOffset, lng: props.item.Location.Longitude });
		} else {
			props.map.setCenter({ lat: props.item.Location.Latitude + yOffset, lng: props.item.Location.Longitude });
		}
	}
	infowindow.value?.addListener('domready', () => {
		(infowindow.value?.getContent() as HTMLElement).parentElement?.addEventListener('mouseleave', ((args) => {
			setActive(false);
		}));
	});
}

function createMarker() {
	infowindow.value = new google.maps.InfoWindow({
		content: root.value?.querySelector('.map-offer-button__hotelbox'),
		headerDisabled: true,
		disableAutoPan: isMobile.value
	});

	marker.value = new google.maps.marker.AdvancedMarkerElement({
		zIndex: props.defaultOpen ? 9000 : props.index,
		position: { lat: props.item.Location.Latitude, lng: props.item.Location.Longitude },
		content: root.value,
		collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
		map: props.map
	});
	marker.value.addListener('click', buttonClick);
}

function mouseLeaveButton(event: MouseEvent) {
	if (isMobile.value) return;
	const relatedTarget = event.relatedTarget;
	if (relatedTarget instanceof HTMLElement && !relatedTarget.classList.contains('map-offer-button__hotelbox')) {
		setActive(false);
	}
}

const formattedPrice = computed((): string => formatPrice(offer.value.Price, offer.value.CurrencyCode));

defineExpose({
	marker,
	setActive,
	props,
	buttonClicked,
});

</script>

<style lang="scss" scoped>
.button.button--outlined {
	color: $color-extra-light;

	&:hover,
	&:focus,
	&:active {
		background-color: $color-extra-light;
	}
}

:deep(.button.button--outlined) {
	border-color: $color-extra-light;
}

.map-offer-button {
	position: relative;
	height: 3.5rem;
	padding: 0;
	font-size: 1.4rem;

	&__button {
		padding: 0.8rem 1.6rem;
		border: 0.1rem solid $color-extra;
		border-radius: $border-radius-xx-large;
		outline: none;
		color: $color-extra;
		font-size: 1.4rem;
		font-weight: 700;
		font-family: $font-family;
		line-height: 1.907rem;
		cursor: pointer;
		background: white;
	}

	&__hotelbox {
		display: none;

		&--active {
			display: inline-block;
		}
	}

	&__hotelbox--hover-only::before { // necessarily for hover state (desktop)
		content: '';
		height: 5rem;
		width: 17rem;
		position: absolute;
		bottom: -2rem;
		left: 50%;
		transform: translate(-50%);
	}
}

.map-offer-button--active {
	.map-offer-button {
		&__button {
			background: $color-extra;
			color: $color-white;
		}
	}
}

</style>
<style>
.gm-style {
	.gm-style-iw-t {
		width: 0;
		height: 0;
		position: relative;
		top: 0;
		left: 0;
		padding: 0;
	}

	.gm-style-iw.gm-style-iw-c {
		width: 28.2rem;
		position: relative;
		top: -5rem;
		left: 0;
		background: transparent;
		border: none;
		box-shadow: none;
		overflow: visible;
		padding: 0;

		button:not(.map-offer-box button) {
			display: none !important;
		}

		&:focus-visible {
			outline: none;
		}
	}

	.gm-style-iw-d {
		width: 28.2rem;
		position: relative;
		top: 0;
		left: 0;
		overflow: visible !important;
		padding: 0;

		.map-offer-button__hotelbox {
			display: inline-block;
		}
	}

	.gm-style-iw-tc {
		display: none;
	}
}
</style>
