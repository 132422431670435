import { createStore } from 'vuex';
import {
	getFluxIbeUrl,
	getIbeBaseUrl,
	getBaseUrl,
	deepMerge,
	determineClient,
	getLocaleString,
	offsetDate,
	getFluxApiUrl,
	determinePageType,
} from '@utils/utils';
import {
	SearchFormDataType,
	Config,
	Types,
	Items,
} from '@interfaces/search-form';
import { ApiDataTypes } from '@components/common/types/index';
import { DEFAULT_DURATION_FILTER } from '@global-js/constants';
import { EventBus } from '@global-js/event-bus';
import { boardTypes, roomTypes, transferTypes } from '@/js/data/index';
import { MergedOfferItemData } from '@/interfaces/offer';
import { convertNumberToTime } from '@/js/utils/dateUtils';
import airports, { airportCodes, defaultAirportCodes } from './items/airports';
import searchMask from './search-mask';
import checkout from './checkout';
import {
	paramsToForm,
	getTravelDurationByQuery,
} from '../services/transform';
import { dashedDate } from '@/js/utils/dateUtils';
import { getAllEnabled as getAllEnabledURLParam } from '../services/url';
import { initBreakpoints } from './breakpoints';

const { protocol, hostname } = window.location;
const ibeBaseUrl = getIbeBaseUrl();
const $store = createStore({
	state() {
		return {
			updated: false,
			items: {
				departure: [],
				airports,
			},
			types: {
				board: boardTypes,
				room: roomTypes,
				transfer: transferTypes,
				operator: [],
				destination: [],
			},
			config: {
				storeInitialized: false,
				isDesktop: true,
				isMinTablet: true,
				isMinTabletLandscape: true,
				isMobile: false,
				redirectOnSubmit: true,
				pageType: determinePageType(),
				ibeBaseUrl,
				apiBase: getFluxIbeUrl(),
				fluxApi: getFluxApiUrl(),
				suggestions: '/v1/search/suggest',
				searchUrl: `${protocol}//${hostname}`,
				ibeUrl: getIbeBaseUrl(),
				baseUrl: getBaseUrl(),
				alterFlights: '/v1/verify-package-offer',
				phone: '',
				phoneLink: '',
				locationType: '',
				client: '',
				verify: {
					package: '/v1/verify-package-offer',
					hotel: '/v1/verify-hotel-offer'
				},
				offers: {
					packages: '/v1/package-offers',
					hotels: '/v1/hotel-offers',
				},
				offerCalendar: {
					package: '/v1/best-package-offer-for-departure-date',
					hotel: '/v1/best-accommodation-offer-for-check-in-date',
				},
				hotelBoxes: {
					package: '/v1/hotel-boxes',
					hotel: '/v1/hotel-boxes',
				},
				hotelList: {
					packages: '/v1/best-package-offer-for-hotel-list',
					hotels: '/v1/best-accommodation-offer-for-hotel-list',
				},
				regionList: {
					packages: '/v1/best-package-offer-for-region',
					hotels: '/v1/best-accommodation-offer-for-region',
				},
				touroperatorInventory: {
					packages: '/v1/package-touroperator-inventory',
					hotels: '/v1/accommodation-touroperator-inventory',
				},
				geoInventory: {
					packages: '/v1/package-geo-inventory',
					hotels: '/v1/accommodation-geo-inventory',
				},
				geoLocation: {
					packages: '/v1/best-package-offer-for-geo-location',
					hotels: '/v1/best-accommodation-offer-for-geo-location',
				},
				hotelOnly: false,
			},
			bestOffer: null,
			calendarDateChanged: false,
			tourOperatorLoading: true,
			destinationFilterLoading: true,
			proxies: {
				initialDestination: null
			},
		};
	},

	getters: {
		getAirportsByCode: (state) => (code: string) => state.items.airports.find((airport: { value: string }) => airport.value === code),

		getBoardByValue: () => (value: string) => boardTypes.find((type: { value: string }) => type.value === value),

		getRoomByValue: () => (value: string) => roomTypes.find((type: { value: string }) => type.value === value),

		locationType: (state): string => state.config.locationType,

		submitData: (state) => (compState: SearchFormDataType) => {
			// duration is set to MaxDuration or defaults to DEFAULT_DURATION_FILTER[1]'
			const duration = Math.max(...compState.travelDuration || DEFAULT_DURATION_FILTER);
			return deepMerge(compState, {
				...(compState.departure.length ? compState.departure : {
					departure: state.items.departure,
				}),
				...(!compState.filter ? {} : {
					offerDuration: {
						from: compState.filter,
						to: offsetDate(compState.filter, duration)
					}
				})
			});
		},

		apiData: () => (data: SearchFormDataType): ApiDataTypes => {
			const RatingAttributes: string[] = [];
			const HotelAttributes: string[] = [];
			const defaultAirports = defaultAirportCodes[determineClient(window.location.href)].split(',');

			RatingAttributes.push(...data.mostPopularFilters.ratingAttributes);
			HotelAttributes.push(...data.mostPopularFilters.hotelAttributes);
			RatingAttributes.push(...data.additionalCheckboxFilters.ratingAttributes);

			return {
				CurrencyCode: getLocaleString('currency'),
				RoomViews: data.roomViews,
				HotelIffCode: data.hotelId,
				StartDate: data.offerDuration.from && dashedDate(data.offerDuration.from),
				DepartureAirport: !data.departure || !data.departure.length ? defaultAirports : data.departure,
				EndDate: data.offerDuration.to && dashedDate(data.offerDuration.to),
				Adults: data.travelers.adult,
				Children: data.travelers.children,
				MinDuration: data.travelDuration ? data.travelDuration[0] : DEFAULT_DURATION_FILTER[0], // default for 'Beliebig'
				MaxDuration: data.travelDuration ? data.travelDuration[1] || data.travelDuration?.[0] : DEFAULT_DURATION_FILTER[1], // default for 'Beliebig'
				BoardTypes: data.boardTypes,
				RoomTypes: data.roomTypes,
				Transfer: data.transferTypes,
				DirectFlight: data.directFlight,
				DefiniteStartDate: !!data.filter, // DefiniteStartDate only if filter is set in best-price component
				Countries: data.locationId,
				Regions: data.rid && data.rid.length > 0 ? data.rid : data.destinationTypes,
				GeoAreaFilter: data.geoAreaFilter && {
					LowerLeft: {
						Latitude: data.geoAreaFilter.lowerLeft.latitude,
						Longitude: data.geoAreaFilter.lowerLeft.longitude,
					},
					UpperRight: {
						Latitude: data.geoAreaFilter.upperRight.latitude,
						Longitude: data.geoAreaFilter.upperRight.longitude,
					}
				},
				RegionGroups: data.regionGroupIds,
				Cities: data.cyid && data.cyid.length > 0 ? data.cyid : data.destinationTypes,
				NumberOfResults: data.numberOfResults,
				SortOrder: data.sortingPricePopular ? 'Popular' : 'Price',
				HotelCategory: data.hotelCategory,
				MinMeanRecommendationRate: data.minMeanRecommendationRate,
				MaxPrice: data.maxPrice === 1600 ? null : data.maxPrice, // if maxPrice is the default of 1600 then we don't want a filter on price
				RatingAttributes,
				HotelAttributes,
				TourOperatorCodes: data.operatorTypes,
				TravelType: data.travelType,
				DepartureTime: (data.minDepartureTime || data.maxDepartureTime) ? {
					MinTime: convertNumberToTime(data.minDepartureTime) ?? '00:00',
					MaxTime: convertNumberToTime(data.maxDepartureTime) ?? '23:59'
				} : undefined,
				ArrivalTime: (data.minArrivalTime || data.maxArrivalTime) ? {
					MinTime: convertNumberToTime(data.minArrivalTime) ?? '00:00',
					MaxTime: convertNumberToTime(data.maxArrivalTime) ?? '23:59'
				} : undefined,
			};
		},
	},

	modules: {
		searchMask,
		checkout,
	},

	actions: {
		initStore({ dispatch }) {
			const pageInfoSelector: HTMLElement | null = document.querySelector('#hotel-list-data, #region-list-data, #typo-data, #region-seasons');
			const globalInfo = (document.querySelector('#global-info') as HTMLElement)?.dataset;

			let initConfigValues = {
				storeInitialized: true,
				phone: globalInfo?.phone,
				phoneLink: globalInfo?.phoneLink,
				client: determineClient(window.location.href),
			};

			if (pageInfoSelector) {
				let searchMaskData = { ...pageInfoSelector?.dataset };
				const query = getAllEnabledURLParam();
				let hotelData;
				if (typeof searchMaskData?.hoteldata === 'string') {
					hotelData = JSON.parse(searchMaskData.hoteldata);
					searchMaskData = { ...searchMaskData, ...hotelData.SeasonSettings };
					searchMaskData.rgid = hotelData.Location.Region.TtRid;
					searchMaskData.cyid = hotelData.Location.City.TtCyid;
					searchMaskData.aid = hotelData.Hotel.IffCode.toString();
					searchMaskData.destinationName = hotelData.Hotel.Name;
				}

				const travelDuration = getTravelDurationByQuery(query);

				let termin = searchMaskData.DepartureDate ?? searchMaskData.termin;
				let ruecktermin = searchMaskData.ReturnDate ?? searchMaskData.ruecktermin;

				const pageType = this.state.config.pageType;
				if (pageType === 'themePage') {
					termin = searchMaskData?.offerDuration?.from ? dashedDate(searchMaskData.offerDuration.from) : undefined;
					ruecktermin = searchMaskData?.offerDuration?.to ? dashedDate(searchMaskData.offerDuration.to) : undefined;
				}

				initConfigValues = {
					...initConfigValues,
					...{
						apiBase: searchMaskData.apiBase,
						hotelOnly: ['hotel', 'Accomodation', 'Hotel'].includes(query.ibe ?? searchMaskData.TravelType ?? searchMaskData.travelType) || searchMaskData.onlyHotel === true,
						rgid: searchMaskData.rid,
						rid: searchMaskData.rgid,
						cyid: searchMaskData.cyid,
						aid: searchMaskData.aid,
						termin,
						ruecktermin,
						locationType: searchMaskData.locationType,
						destinationName: searchMaskData.destinationName,
						country: searchMaskData.countryCode,
						displayMode: searchMaskData.displaymode,
					}
				};

				dispatch('initItems', {
					...{ airports: airportCodes[determineClient(window.location.href)] },
					...{ departure: searchMaskData.departure },
					...(searchMaskData.region ? { region: searchMaskData.region } : {})
				});

				dispatch('initFormData', {
					...{
						adult: searchMaskData.adult,
						aid: searchMaskData.aid,
						ddate: termin,
						depap: searchMaskData.depap,
						dur: travelDuration,
						ibe: searchMaskData.ibe,
						rdate: ruecktermin,
						board: searchMaskData.board,
						room: searchMaskData.room,
						dfl: searchMaskData.dfl,
						filter: searchMaskData.filter,
						coname: searchMaskData.countryName,
						trname: searchMaskData.topRegionName,
						cyname: searchMaskData.cityName,
						hotelname: searchMaskData.destinationName,
						srtHot: '101',
					},
					...query,
				});

				EventBus.$emit('Store:Initialized');
			} else {
				dispatch('initItems', {
					...{ airports: airportCodes[determineClient(window.location.href)] },
				});
			}

			type testkey = keyof typeof initConfigValues;
			Object.keys(initConfigValues).forEach((key) => initConfigValues[(key as testkey)] === undefined && delete initConfigValues[key as testkey]);
			dispatch('initConfig', initConfigValues);
		},

		initConfig({ commit }, data) {
			if (Object.keys(data).length) {
				commit('updateConfig', data);
			}
		},

		initItems({ commit, getters }, data) {
			const parsed = Object.keys(data).reduce((acc, key) => {
				let values = data[key];
				// if comma separated list, split it
				if (/[,]/g.test(data[key]) && !Array.isArray(data[key])) {
					// split + trim
					values = data[key].split(',').map((value: string) => value.trim());
				}

				// get airports by code
				if (['airports'].indexOf(key) !== -1) {
					values = values.map((val: string) => (getters.getAirportsByCode(val) || { label: val, value: val, additional: [val] }));
				}

				return { ...acc, [key]: values };
			}, {} as Items);

			if (Object.keys(parsed).length) {
				commit('updateItem', parsed);
			}
		},

		initFormData({ dispatch }, data) {
			if (data.rid && this.state.config.locationType === 'TOPREGION') {
				// eslint-disable-next-line no-param-reassign
				data.rid = undefined;
			}
			if (data.cyid && this.state.config.locationType === 'COUNTRY') {
				// eslint-disable-next-line no-param-reassign
				data.cyid = undefined;
			}
			const updated = paramsToForm(data);

			if (Object.keys(updated).length) {
				dispatch('setFormData', updated);
			}
		},

		updateTypes({ commit }, data) {
			if (Object.keys(data).length) {
				commit('UPDATE_TYPES', data);
			}
		},

		updateTourOperatorLoading({ commit }, data) {
			commit('UPDATE_TOUR_OPERATOR_LOADING', data);
		},

		updateDestinationFilterLoading({ commit }, data) {
			commit('UPDATE_DESTINATION_FILTER_LOADING', data);
		},

		updateProxies({ commit }, data): void {
			commit('UPDATE_PROXIES', data);
		},
	},

	mutations: {
		updateConfig(state, update: Config) {
			// eslint-disable-next-line no-param-reassign
			state.config = { ...state.config, ...update };
		},

		UPDATE_TYPES(state, update: Types) {
			// eslint-disable-next-line no-param-reassign
			state.types = { ...state.types, ...update };
		},

		updateItem(state, update: Items) {
			// eslint-disable-next-line no-param-reassign
			state.items = { ...state.items, ...update };
		},

		updateBestOffer(state, update: Partial<MergedOfferItemData>) {
			// eslint-disable-next-line no-param-reassign
			state.bestOffer = update;
		},

		updateCalendarDateChanged(state, update: boolean) {
			// eslint-disable-next-line no-param-reassign
			state.calendarDateChanged = update;
		},

		UPDATE_TOUR_OPERATOR_LOADING(state, update: boolean) {
			// eslint-disable-next-line no-param-reassign
			state.tourOperatorLoading = update;
		},

		UPDATE_DESTINATION_FILTER_LOADING(state, update: boolean) {
			// eslint-disable-next-line no-param-reassign
			state.destinationFilterLoading = update;
		},

		UPDATE_PROXIES(state, update): void {
			// eslint-disable-next-line no-param-reassign
			state.proxies = { ...state.proxies, ...update };
		},
	}
});
initBreakpoints($store);

// set singleton
global.$store = global.$store || $store;
export const useStore = () => $store;
export default global.$store;

$store.dispatch('initStore');
