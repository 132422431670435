<template>
	<SearchFormModal
		v-if="!isDesktop"
		ref="searchFormModal"
		header="Suche anpassen"
		apply-button="Suchen"
		cancel-button="Abbrechen"
	/>
	<FilterModal
		v-if="!isDesktop"
		ref="filterModal"
		:is-hotel-page="false"
		header="Filter anpassen"
		apply-button="Suchen"
		cancel-button="Abbrechen"
	/>
	<Teleport
		:to="mobileTeleportTarget"
		:disabled="!mobileTeleportTarget"
	>
		<div class="list-page-search__mobile-filters">
			<ThemeMinimal
				class="list-page-search__theme-minimal"
				@click="() => searchFormModal!.openModal()"
			/>
			<SearchBar
				class="list-page-search__serach-bar"
				:hasMap="hasMap"
				:hasSort="hasSort"
				@SearchBar:openFilterModal="() => filterModal!.openModal()"
			/>
		</div>
	</Teleport>
	<section class="list-page-search__searchForm">
		<SearchForm />
	</section>
</template>

<script lang="ts" setup>
import ThemeMinimal from '@lmt-rpb/ThemeMinimal/ThemeMinimal.vue';
import SearchBar from '@lmt-rpb/SearchBar/SearchBar.vue';
import FilterModal from '@lmt-rpb/FilterModal/FilterModal.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import { computed, onMounted, ref} from 'vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { useStore } from '@/components/common/store';

interface Props {
	mobileTeleportTarget?: string,
	hasMap?: boolean
	hasSort?: boolean
}

withDefaults(defineProps<Props>(), {
	hasMap: false,
	hasSort: false,
});

const store = useStore();

const isDesktop = computed(() => store.state.config.isDesktop);

const filterModal = ref<InstanceType<typeof FilterModal>>();

const searchFormModal = ref<InstanceType<typeof SearchModal>>();

onMounted(() => {
	document.body.classList.add('page--mobile-search-bar');
});

defineExpose({
	filterModal,
	searchFormModal,
});

</script>

<style lang="scss" scoped>
.list-page-search {
	&__searchForm {
		display: none;
	}

	&__theme-minimal {
		position: relative;
		z-index: 2;
	}

	&__serach-bar {
		position: relative;
		z-index: 1;
	}

	&__mobile-filters {
		padding: 0 !important;
		position: sticky;
		top: 0;
		z-index: 5000;
		transition: top 0.15s ease-in-out;
	}

	@media screen and (min-width: $breakpoint-extralarge) {
		&__theme-minimal {
			display: none;
		}

		&__searchForm {
			display: block;
		}
	}
}

.page-header--hidden .list-page-search__mobile-filters {
	top: -7.1rem;
}
</style>
