<template>
	<transition
		ref="componentRef"
		name="scale-and-fade"
	>
		<div
			v-if="render"
			v-show="showModal"
			class="modal"
			:class="modalClasses"
		>
			<div
				class="modal__header"
				@click="hide()"
			>
				<button
					v-if="customIcon"
					type="button"
					class="modal__custom-icon"
				>
					<BaseIcon name="chevron"></BaseIcon>
				</button>
				<h2 class="modal__title">
					{{ title }}
					<span
						v-if="board"
						class="modal__title--small-text"
					> (mind.)</span>
				</h2>
				<button
					type="button"
					class="modal__close"
				>
					<svg
						v-if="!customIcon"
						width="18"
						height="18"
						viewBox="0 0 27 32"
					>
						<title>close</title>
						<path d="M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" />
					</svg>
				</button>
				<div class="modal__subheader">
					<slot name="modal-subheader"></slot>
				</div>
			</div>
			<div class="modal__body">
				<div class="modal__content-top">
					<slot name="content-top"></slot>
				</div>
				<div class="modal__content">
					<slot></slot>
				</div>
				<div class="modal__content-bottom">
					<slot name="content-bottom"></slot>
				</div>
			</div>
			<div
				v-if="withFooter"
				class="modal__footer"
			>
				<p
					v-if="errorMessage"
					class="modal__footer-warning"
				>
					{{ errorMessage }}
				</p>

				<div class="modal__footer-wrap">
					<div class="modal__footer-hide-button">
						<rounded-button
							theme="outlined"
							class="modal__footer-button modal__footer-button--hide"
							@click="hide()"
						>
							{{ cancelButtonLabel }}
						</rounded-button>
					</div>
					<div class="modal__footer-ok-button">
						<rounded-button
							class="modal__footer-button modal__footer-button--ok"
							:disabled="!!errorMessage"
							@click="ok()"
						>
							{{ acceptButtonLabel }}
						</rounded-button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ModalManager from '@/js/modules/modalManager';

const emit = defineEmits(['update:modelValue', 'Modal:Cancel', 'Modal:Ok', 'Modal:Close']);

interface Props {
	modelValue?: boolean,
	title: string,
	offerDurationModal?: boolean,
	initialRender?: boolean,
	board?: boolean,
	errorMessage?: string,
	customIcon?: string,
	withFooter?: boolean,
	cancelButtonLabel?: string,
	acceptButtonLabel?: string,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
	title: 'Title',
	offerDurationModal: false,
	initialRender: false,
	board: false,
	customIcon: '',
	withFooter: true,
	errorMessage: '',
	cancelButtonLabel: 'Abbrechen',
	acceptButtonLabel: 'OK',
});

const componentRef = ref();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: boolean) {
		emit('update:modelValue', newValue);
	}
});

const parent = ref<HTMLElement | null>(null);

const root = ref();

const showModal = ref<boolean>(model.value);

const render = ref(props.initialRender);

const isClosing = ref(false);

const bodyElem = computed((): HTMLElement => document && document.body);

const modalClasses = computed((): Record<string, any> => ({
	'is-open': showModal.value,
	'is-closing': isClosing.value
}));

const isHeaderSearch = computed((): boolean => root.value?.type.name === 'SearchForm-Header' || root.value?.type.name === 'Solr-Search');

const close = (): void => {
	emit('update:modelValue', false);
};

const onPopState = (): void => {
	if (showModal.value) {
		close();
	}
};

const ok = (): void => {
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
		emit('Modal:Ok');
	}
};

const hide = (): void => {
	emit('Modal:Cancel');
	close();
	if (window.history.state && window.history.state.modal) {
		ModalManager.closeModalAndChildren(componentRef.value);
	}
};

onBeforeUnmount(() => {
	EventBus.$off('OfferDuration:ModalClose', close);
	EventBus.$off('window:popstate', close);
});

onMounted(() => {
	parent.value = getCurrentInstance()?.parent?.vnode.el as unknown as HTMLElement;
	root.value = getCurrentInstance()?.root;

	const mql = window.matchMedia('(min-width: 1300px)');

	mql.addListener(function(e) {
		if (e.matches) {
			close();
		}
	});

	const unwatch = watch(() => model.value, () => {
		if (!model.value) {
			return;
		}

		render.value = true;
		unwatch();
	});

	EventBus.$on('OfferDuration:ModalClose', close);
});

watch(() => model.value, (next: boolean, prev: boolean) => {
	if (next === false && prev === true) {
		setTimeout(() => {
			isClosing.value = false;
			showModal.value = false;
		}, 100);

		isClosing.value = true;
		EventBus.$off('window:popstate', onPopState);
	} else {
		showModal.value = model.value;
		window.history.pushState({ modal: true }, '');
		EventBus.$on('window:popstate', onPopState);
		ModalManager.modalOpenHistory.push(componentRef.value);
	}

	if (root.value?.type.name === 'Solr-Search') {
		bodyElem.value.classList.toggle('remove-back-content', model.value);
	}
	if (!isHeaderSearch.value) {
		bodyElem.value.classList.toggle('no-scroll', model.value);
	} else {
		const headerSearch = document.querySelector('.rpb_header-search');
		if (headerSearch) {
			headerSearch.classList.toggle('rpb_header-search--open-modal');
		}
	}
});

defineExpose({
	showModal, model
});

</script>

<style lang="scss" scoped>
.hide-content main,
.hide-content footer {
	display: none;

	@include media-query-up($breakpoint-container) {
		display: block;
	}
}

@keyframes headerIn {
	from {
		transform: translateX(-3rem);
		opacity: 0;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes headerOut {
	from {
		transform: translateX(0);
		opacity: 1;
	}

	to {
		transform: translateX(-3rem);
		opacity: 0;
	}
}

.modal {
	display: flex;
	position: fixed;
	z-index: 5001;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	background: $color-white;

	.modal__header {
		position: relative;
		padding: 1rem 1.5rem 1rem 3rem;
		border-bottom: 0.1rem solid $color-primary-l4;
		background: $color-white;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal__custom-icon {
		width: auto;
		border: none;
		background-color: transparent;
		height: 2.2rem;
		transform: rotateZ(270deg);

		& svg {
			height: 1.1rem;
			fill: $color-primary;
		}
	}

	.modal__title {
		padding: 0;
		opacity: 0;
		font-family: $font-family;
		font-size: $font-small;
		font-weight: 600;
		text-align: left;
	}

	&__title--small-text {
		font-size: 1.2rem;
	}
	.modal__subheader {
		font-size: 1.4rem;
		padding-left: 0.1rem;
		color: $color-dark-gray;
	}

	.modal__close {
		position: absolute;
		top: 50%;
		height: 2rem;
		right: 1rem;
		transform: translateY(-50%);
		border: none;
		background: none;
		fill: $color-primary;
		cursor: pointer;
		z-index: 1;

		&:focus,
		&:active {
			outline: none;
		}
	}

	.modal__body {
		display: flex;
		position: relative;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;
	}

	.modal__content {
		max-width: 54rem;
		width: 100%;
		margin: 0 auto;
		height: 100%;
		overflow: auto;
	}

	.modal__content-bottom {
		padding-top: 0.1rem;
	}

	.modal__footer {
		height: fit-content;
		background: $color-light-gray;
	}

	&__footer-warning {
		text-align: center;
		color: $color-warning;
		font-size: 1.4rem;
		margin: 1.5rem 0;
	}

	.modal__footer-wrap {
		@include max-width(50rem);

		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.3rem;
		margin-bottom: 1.3rem;
	}

	&__footer-hide-button,
	&__footer-ok-button {
		display: contents;
		width: 100%;
		height: 100%;
	}

	&__footer-ok-button {
		button {
			border: 0.1rem solid $color-primary;
		}

		button.is-disabled {
			border: 0.2rem solid $color-dark-gray;
		}
	}

	.modal__footer-button {
		flex: 1 1 50%;
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	&.is-open {
		.modal__title,
		.modal__subheader {
			animation: headerIn forwards 0.1s 0.25s;
		}
	}

	&.is-closing {
		.modal__title,
		.modal__subheader {
			animation: headerOut forwards 0.1s 0s;
		}
	}

	:deep(.form-field__chevron-icon) {
		display: none;
	}

	@include media-query-up ($breakpoint-small) {
		.modal__header {
			height: 7rem;
			padding: 2rem 1.5rem 2rem 3rem;
		}

		.modal__title,
		.modal__subheader {
			text-align: center;
		}

		.modal__footer {
			padding: 0;
		}
	}
}

.scale-and-fade-enter-active,
.scale-and-fade-leave-active {
	transition: all 0.25s;
}

.scale-and-fade-enter-active {
	transition-delay: 0.2s;
}

.scale-and-fade-leave-active {
	transition-delay: 0s;
}

.scale-and-fade-enter-from,
.scale-and-fade-leave-to {
	opacity: 0;
}

</style>
