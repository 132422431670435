<template>
	<div class="payment-method">
		<div class="payment-method__header">
			<h3
				v-if="paymentProcessor.isFtiGroup"
				class="payment-method__headline"
			>
				Zahlungsart:

				<div
					v-if="paymentProcessor.isFtiGroup && paymentProcessor.acceptCreditCard"
					class="payment-method__info-fti"
				>
					Kreditkarte
					<span class="payment-method__sub-headline">
						gebührenfrei
					</span>
				</div>
			</h3>
			<h3
				v-else
				class="payment-method__headline"
			>
				Bevorzugte Zahlungsart:
			</h3>
			<CheckoutPaymentMethodInfo
				v-if="!isMobile"
			/>
		</div>
		<span
			v-if="!paymentRadioValid && paymentRadioValidated"
			class="payment-radio-group__error"
		>
			<BaseIcon
				name="exclamationMarkFill"
				class="payment-radio-group__exclamation"
			/>
			Zahlungsart auswählen
		</span>
		<PaymentRadioButtonGroup
			v-if="!paymentProcessor.isFtiGroup"
			id="payment-type"
			v-model="paymentInfo.selectedPayment"
			:class="{ 'payment-radio-group__error':!paymentRadioValid && paymentRadioValidated }"
			:options="overallPaymentMethods"
		/>

		<div
			v-if="paymentInfo.selectedPayment === 'Kreditkarte'"
			class="payment-method__info-container"
		>
			<h3 class="payment-method__headline-add">
				Kreditkartenanbieter
			</h3>
			<span
				v-if="!creditCardValid && creditCardValidated"
				class="payment-radio-group__error"
			>
				Kreditkarte auswählen
			</span>
			<CreditCardRadioButtonGroup
				id="credit-card-radio-group"
				v-model="paymentInfo.selectedCreditCard"
				:class="{ 'payment-radio-group__error': !creditCardValid && creditCardValidated }"
				:options="creditCards"
			/>

			<p
				v-if="creditCardServiceText"
				class="payment-method__credit-card-service-text"
			>
				{{ creditCardServiceText }}
			</p>
			<div class="payment-method__credit-card-info">
				<p class="payment-method__credit-card-info-text">
					Wählen Sie hier Ihre Kreditkarte, <strong>
						die Eingabe Ihrer Kreditkartendaten erfolgt nach Abschluss der Buchung.
					</strong>
				</p>
				<BaseIcon
					class="payment-method__question-icon"
					name="questionCircle"
					@keypress.enter.native="showInfoText = !showInfoText"
					@mouseover.native="showInfoText = true"
					@mouseleave.native="showInfoText = false"
				/>
				<transition name="info-text">
					<div
						v-if="showInfoText"
						class="payment-method__info-bubble"
					>
						<span>Die <strong>Eingabe Ihrer Kreditkartendaten</strong> erfolgt im nächsten Schritt,
							<strong>nach Abschluß der Buchung.</strong> Dabei werden Sie, wie im Rahmen der neuen
							Zahlungsdiensterichtlinie (PSD2) festgelegt, <strong>für die Authentifizierung zu Ihrer Bank
								weitergeleitet, um die Zahlung des Gesamtbetrags zu bestätigen.</strong> Danach werden
							Sie wieder zu uns zurückgeleitet.</span>
					</div>
				</transition>
			</div>
		</div>
		<div
			v-else-if="(paymentInfo.selectedPayment === 'Banküberweisung' || paymentProcessor.isFtiGroup) && paymentOptions['BankTransfer'].serviceText"
			class="payment-method__info-container"
		>
			<h3
				class="payment-method__headline"
				:class="{'payment-method__headline-without-buttons': paymentProcessor.isFtiGroup}"
			>
				{{ selectedPaymentOption?.header ?? paymentInfo.selectedPayment }}
				<span
					v-if="paymentProcessor.isFtiGroup"
					class="payment-method__sub-headline"
				>
					gebührenfrei
				</span>
			</h3>
			<p class="payment-method__bank-transfer-info">
				{{ paymentOptions['BankTransfer'].serviceText }}
			</p>
		</div>
		<div
			v-show="selectedPaymentIsSepa"
			class="payment-method__info-container"
		>
			<h3 class="payment-method__headline-add sepa">
				Zahlung per Lastschriftmandat
			</h3>
			<p
				v-if="selectedPaymentIsSepa && paymentOptions['DirectDebitInternational'].serviceText"
				class="payment-method__sepa-info"
			>
				{{ paymentOptions['DirectDebitInternational'].serviceText }}
			</p>

			<CheckoutSepaFormFields
				unique-key="sepa"
				:step-nr="CHECKOUT_OTHER_CUSTOMER_ID"
				:validate-form="selectedPaymentIsSepa"
			/>
		</div>
		<h3
			v-if="selectedPaymentOption?.header"
			class="payment-method__headline-add"
		>
			{{ selectedPaymentOption.header }}
		</h3>
		<p
			v-if="selectedPaymentOption?.serviceText && !selectedPaymentIsSepa"
			class="payment-method__payment-due-text"
		>
			{{ selectedPaymentOption?.serviceText }}
		</p>
		<h3 class="payment-method__headline-add">
			Zahlungsmodalitäten für Zahlung der Reise:
		</h3>
		<p
			v-if="depositAmount"
			class="payment-method__payment-due-text"
			v-html="depositText"
		></p>
		<p
			class="payment-method__payment-due-text"
			v-html="finalPaymentText"
		></p>
		<CheckoutPaymentMethodInfo v-if="isMobile" />
	</div>
</template>

<script lang="ts" setup>
import PaymentRadioButtonGroup from '@lmt-rpb/PaymentRadioButtonGroup/PaymentRadioButtonGroup.vue';
import CreditCardRadioButtonGroup from '@lmt-rpb/CreditCardRadioButtonGroup/CreditCardRadioButtonGroup.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import CheckoutSepaFormFields from '@lmt-rpb/CheckoutSepaFormFields/CheckoutSepaFormFields.vue';
import { formatDate } from '@utils/utils';
import * as checkoutUtils from '@utils/checkoutUtils';
import { PriceInformation, SimplePrice } from '@/interfaces/checkout/offer-data';
import { PaymentOptions } from '@/interfaces/checkout/checkoutTypes';
import { PAYMENT_TYPES, CHECKOUT_OTHER_CUSTOMER_ID } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';
import { CheckoutValidationState } from '@/components/common/store/checkout-validation-state';
import CheckoutPaymentMethodInfo from '@lmt-rpb/CheckoutPaymentMethodInfo/CheckoutPaymentMethodInfo.vue';

const store = useStore();
interface Props {
	paymentOptions: PaymentOptions,
	priceInfo: PriceInformation,
	paymentProcessor: { isFtiGroup: boolean; acceptCreditCard: boolean},
	flexStornoAmount?: SimplePrice,
}

const selectedPaymentOption = computed(() => Object.values(props.paymentOptions).find((option) => option.label === paymentInfo.value?.selectedPayment));

const props = defineProps<Props>();

const emit = defineEmits(['CheckoutPaymentMethod:PaymentInfoChanged']);

// Init field in store
if (props.paymentProcessor.isFtiGroup) {
	CheckoutValidationState.value['payment-type'] = {
		stepNr: CHECKOUT_OTHER_CUSTOMER_ID, valid: true, validated: true, hasBackendError: false
	};

	if (props.paymentProcessor.acceptCreditCard) {
		CheckoutValidationState.value['credit-card-radio-group'] = {
			stepNr: CHECKOUT_OTHER_CUSTOMER_ID, valid: false, validated: false, hasBackendError: false
		};
	} else {
		CheckoutValidationState.value['credit-card-radio-group'] = {
			stepNr: CHECKOUT_OTHER_CUSTOMER_ID, valid: true, validated: true, hasBackendError: false
		};
	}
} else {
	CheckoutValidationState.value['payment-type'] = {
		stepNr: CHECKOUT_OTHER_CUSTOMER_ID, valid: false, validated: false, hasBackendError: false
	};
	CheckoutValidationState.value['credit-card-radio-group'] = {
		stepNr: CHECKOUT_OTHER_CUSTOMER_ID, valid: false, validated: false, hasBackendError: false
	};
}
const isMobile = computed(() => store.state.config.isMobile);

const paymentRadioValid = computed((): boolean => CheckoutValidationState.value['payment-type'].valid);
const paymentRadioValidated = computed((): boolean => CheckoutValidationState.value['payment-type'].validated);

const creditCardValid = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].valid);
const creditCardValidated = computed((): boolean => CheckoutValidationState.value['credit-card-radio-group'].validated);

const showInfoText = ref(false);

const determineInitPaymentMethod = (): string => {
	const { isFtiGroup, acceptCreditCard } = props.paymentProcessor;

	if (isFtiGroup) {
		return acceptCreditCard ? 'Kreditkarte' : 'Rechnung';
	}
	return '';
};

const paymentInfo = ref({
	selectedPayment: determineInitPaymentMethod(),
	selectedCreditCard: '',
});

const selectedPaymentIsSepa = computed(() => paymentInfo.value.selectedPayment === 'SEPA-Lastschrift');

const creditCards = computed(() => {
	const cards: string[] = [];
	Object.values(props.paymentOptions).forEach((value) => {
		if (value.type === 'CreditCard') cards.push(value.label);
	});
	return cards;
});

const overallPaymentMethods = computed(() => {
	const methods: string[] = [];
	Object.values(props.paymentOptions).forEach((value) => {
		if (value.type !== 'CreditCard') {
			methods.push(value.label);
		}
	});

	if (creditCards.value.length) {
		methods.push('Kreditkarte');
	}
	return methods;
});

const creditCardServiceText = computed((): string => {
	let serviceText = '';
	Object.values(props.paymentOptions).forEach((value) => {
		if (
			value.label === paymentInfo.value.selectedCreditCard
				&& value.serviceText
		) {
			serviceText = value.serviceText;
		}
	});
	return serviceText;
});

const depositAmount = computed(() => checkoutUtils.getDepositAmount(props.priceInfo, props.flexStornoAmount));

const depositText = computed((): string => {
	const depositAmountConverted = checkoutUtils.getDepositAmountConverted(props.priceInfo, props.flexStornoAmount);
	if (!props.priceInfo?.Deposit?.DueDate) return '';

	if (new Date().toISOString().split('T')[0] === props.priceInfo.Deposit.DueDate) {
		if (depositAmountConverted) {
			return `Die Anzahlung von <strong>${depositAmountConverted}</strong> (${depositAmount.value}) ist sofort fällig.`;
		}
		return `Die Anzahlung von <strong>${depositAmount.value}</strong> ist sofort fällig.`;
	}

	const formatedDate = formatDate(props.priceInfo.Deposit.DueDate, { year: 'numeric', month: '2-digit', day: '2-digit' });

	if (depositAmountConverted) {
		return `Die Anzahlung von <strong>${depositAmountConverted}</strong> (${depositAmount.value}) ist fällig bis zum ${formatedDate}.`;
	}
	return `Die Anzahlung von <strong>${depositAmount.value}</strong> ist fällig bis zum ${formatedDate}.`;
});

const finalPaymentText = computed((): string => {
	let finalPayment = checkoutUtils.getFinalPayment(props.priceInfo, props.flexStornoAmount);
	let finalPaymentConverted = checkoutUtils.getFinalPaymentConverted(props.priceInfo, props.flexStornoAmount);
	if (!props.priceInfo?.FinalPayment?.DueDate) return 'Die Höhe und Fälligkeit von An- und Restzahlung finden Sie unten in den Allgemeinen Geschäftsbedingungen Ihres Reiseveranstalters unter den Zahlungsbedingungen.';

	if (!depositAmount.value) {
		if (finalPaymentConverted) {
			return `Die Zahlung von <strong>${finalPaymentConverted}</strong> (${finalPayment}) ist sofort fällig. `;
		}
		return `Die Zahlung von <strong>${finalPayment}</strong> ist sofort fällig.`;
	}

	const finalDueDate = formatDate(props.priceInfo.FinalPayment.DueDate, { year: 'numeric', month: '2-digit', day: '2-digit' });
	finalPayment = checkoutUtils.getFinalPayment(props.priceInfo);
	finalPaymentConverted = checkoutUtils.getFinalPaymentConverted(props.priceInfo);

	if (finalPaymentConverted) {
		return `Restzahlung  <strong>${finalPaymentConverted}</strong> (${finalPayment}) bis zum ${finalDueDate}.`;
	}
	return `Restzahlung <strong>${finalPayment}</strong> bis zum ${finalDueDate}.`;
});

const selectBankTransfer = () => {
	const paymentType = 'BankTransfer';
	store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
	emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
};

onMounted(() => {
	if (props.paymentProcessor.isFtiGroup && !props.paymentProcessor.acceptCreditCard) {
		selectBankTransfer();
	}
});

watch(() => paymentInfo.value, () => {
	const payment = paymentInfo.value;
	if (payment.selectedPayment === 'Kreditkarte') {
		let paymentMethodSelected = '';
		Object.entries(PAYMENT_TYPES).forEach(([paymentMethod, value]) => {
			if (value.label === payment.selectedCreditCard) {
				paymentMethodSelected = paymentMethod;
				CheckoutValidationState.value['credit-card-radio-group'].valid = true;
				CheckoutValidationState.value['credit-card-radio-group'].validated = true;
			}
		});
		CheckoutValidationState.value['payment-type'].valid = true;
		CheckoutValidationState.value['payment-type'].validated = true;
		emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentMethodSelected);
		store.dispatch('checkout/updateTravel', { PaymentType: paymentMethodSelected });
		return;
	}
	if (payment.selectedPayment === 'Banküberweisung' || payment.selectedPayment.toLocaleLowerCase().includes('überweisung')) {
		selectBankTransfer();
	}
	if (selectedPaymentIsSepa.value) {
		const paymentType = 'DirectDebitInternational';
		emit('CheckoutPaymentMethod:PaymentInfoChanged', paymentType);
		store.dispatch('checkout/updateTravel', { PaymentType: paymentType });
	}

	CheckoutValidationState.value['payment-type'].valid = true;
	CheckoutValidationState.value['payment-type'].validated = true;
}, { deep: true });

</script>

<style lang="scss" scoped>
:deep(.payment-radio-button-group) {
	margin: 1.6rem 0;
}

:deep(.payment-method-info__container) {
	margin-top: 1.6rem;

	@media (min-width: $breakpoint-verysmall) {
		margin-top: -6.4rem;
		margin-bottom: unset;
	}
}

#payment-radio-group,
#credit-card-radio-group {
	scroll-margin-top: $scroll-margin-top;
}

.payment-radio-group__error {
	color: $color-warning-2 !important;
	border: none;
	font-size: $font-tiny-small;
	width: unset;
	margin-bottom: 0.5rem;
	font-weight: 600;

	:deep(label) {
		border: 0.2rem solid $color-warning-2;
	}

	:deep(input) {
		border: 0.2rem solid $color-warning-2;
	}

	@media (min-width: $breakpoint-large) {
		font-size: $font-very-small;
	}
}

.payment-radio-group__exclamation {
	fill: $color-warning-2 !important;
	margin-right: 0.5rem;
}

.payment-method {
	padding: 0 1.8rem 1.6rem;

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&__headline {
		min-width: 24.5rem;
		padding: 0;
		text-align: left;
		font-weight: bold;
		font-size: $font-medium;
		margin-bottom: 1.7rem;

		&-add {
			// we have servicetext above the headline
			p + & {
				margin-top: 1.6rem;
			}

			padding: 0;
			text-align: left;
			font-weight: bold;
			margin-bottom: 0.8rem;
			font-size: $font-small;
		}
	}

	&__headline-without-buttons {
		font-size: $font-medium;
	}

	&__sub-headline {
		display: block;
		font-weight: 400;
		color: $color-green-text;
		font-size: $font-very-small;
	}

	&__credit-card-info {
		margin-top: 1.5rem;
		position: relative;
		display: flex;
		justify-content: space-between;
		border: 0.1rem solid $color-primary-l4;
		border-radius: $border-radius-x-small;
		padding: 0.6rem 1.4rem;
	}

	&__credit-card-info-text,
	&__credit-card-service-text {
		font-size: $font-very-small;
		margin: 0;
		line-height: 1.7;
		max-width: 90%;
	}

	&__credit-card-service-text {
		margin-bottom: 1.6rem;
	}

	&__question-icon {
		align-self: center;
		flex-shrink: 0;
	}

	&__info-bubble {
		pointer-events: none;
		position: absolute;
		display: inline-block;
		bottom: 11rem;
		left: 0;
		font-size: $font-small;
		padding: 1.6rem;
		background: #44A678;
		color: $color-white;
		border-radius: $border-radius-small;
	}

	&__info-bubble::after {
		content: '';
		bottom: -3rem;
		right: 1rem;
		position: absolute;
		border: 2rem solid transparent;
		border-top-color: #44A678;
	}

	// Vue Transitionsbase
	.info-text-enter-from,
	.info-text-leave-to {
		visibility: hidden;
		opacity: 0;
	}

	.info-text-enter-active,
	.info-text-leave-active {
		transition: all 0.3s;
	}

	&__bank-transfer-info,
	&__sepa-info {
		font-size: $font-small;
		line-height: 1.7;
	}

	&__info-fti {
		margin-top: 1.8rem;
	}

	&__info-container {
		margin-bottom: 1.6rem;
	}

	&__payment-due-text {
		font-size: $font-very-small;
		margin-bottom: 0.3rem;
	}

	@media screen and (min-width: $breakpoint-mobilelarge) {
		&__info-bubble {
			bottom: 9rem;
		}
	}

	@media screen and (min-width: $breakpoint-verysmall) {
		padding: 1.6rem 0.8rem;

		&__info-bubble {
			bottom: 8rem;
		}
	}

	@media screen and (min-width: $breakpoint-small) {
		&__info-container {
			margin-bottom: 2.4rem;
		}

		&__info-bubble {
			bottom: 7rem;
		}
	}

	@media (min-width: $breakpoint-large) {
		padding: 2.4rem 3.6rem;

		&__payment-due-text {
			font-size: $font-small;
		}

		&__headline {
			font-size: $font-almost-large;
		}
	}
}
</style>
