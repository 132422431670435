/* eslint-disable no-param-reassign */
import OldModal from '@lmt-rpb/Modal/Modal.vue';
import { EventBus } from '@global-js/event-bus';
import NewModal from './modal';

type Modal = NewModal | InstanceType<typeof OldModal>

export default class ModalManager {
	public static modalOpenHistory: Modal[] = [];

	private static savedCurrentHref: string | null = null;

	public static redirectTo: string;

	public static historyBackCalledByBrowser: boolean;

	static {
		window.addEventListener('popstate', () => {
			this.closeLastModal();
		});
	}

	static closeLastModal() {
		const lastPushedModal = this.modalOpenHistory[this.modalOpenHistory.length - 1];
		if (lastPushedModal) {
			this.closeModal(lastPushedModal);
			EventBus.$emit('lastModalClosed');
			EventBus.$off('lastModalClosed');
		}
		this.historyBackCalledByBrowser = true;
	}

	static closeModal(modal: Modal) {
		if (modal instanceof NewModal && modal.modalRef.open) {
			modal.hide(); // new Modal
			if (this.historyBackCalledByBrowser && typeof modal.handleHistoryBack === 'function') {
				modal.handleHistoryBack();
			}
		} else if (!(modal instanceof NewModal) && modal) {
			modal.model = false; // old Modal
		}
		this.modalOpenHistory.pop();
		if (this.modalOpenHistory.length === 0) {
			document.body.style.overflow = 'auto';
		}
		if (this.redirectTo) {
			window.location.href = this.redirectTo;
		}
		if (this.savedCurrentHref) {
			window.history.replaceState(window.history.state, '', this.savedCurrentHref); // recover changed href after closing (mobile search)
		}
		this.savedCurrentHref = null;
	}

	static closeModalAndChildren(modal: Modal) {
		if (this.modalOpenHistory.includes(modal)) {
			this.savedCurrentHref = window.location.href;
			this.historyBackCalledByBrowser = false;
			window.history.back();
			EventBus.$on('lastModalClosed', () => { this.closeModalAndChildren(modal); });
		}
	}
}
