import type {
	OfferStatusCheck,
	OfferIsAvailable,
	OfferCanBeChecked,
} from '@/interfaces/offer';
import { OFFER_CAN_BE_CHECKED_STATUS, OFFER_IS_AVAILABLE_STATUS, OFFER_IS_ON_REQUEST_STATUS } from '@global-js/constants';
import { computed, type ComputedRef, type Ref } from 'vue';

export const useOfferStatus = (status: Ref<OfferStatusCheck | undefined> | ComputedRef<OfferStatusCheck | undefined>) => {
	const isOfferAvailable = computed(() => OFFER_IS_AVAILABLE_STATUS.includes(status.value as OfferIsAvailable));
	const offerCanBeChecked = computed(() => OFFER_CAN_BE_CHECKED_STATUS.includes(status.value as OfferCanBeChecked));
	const isOfferOnRequest = computed(() => OFFER_IS_ON_REQUEST_STATUS === status.value);

	return {
		isOfferAvailable,
		offerCanBeChecked,
		isOfferOnRequest
	};
};
