<template>
	<SearchModal
		ref="searchModal"
		:header="props.header"
		:apply-button="props.applyButton"
		:cancel-button="props.cancelButton"
		@SearchModal:applyChanges="handleApply"
		@SearchModal:close="handleClose"
	>
		<SearchForm
			ref="searchForm"
			:is-header-search="false"
		/>
	</SearchModal>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import store from '@/components/common/store';
import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import { EventBus } from '@global-js/event-bus';

const searchModal = ref<typeof SearchModal | null>(null);

const searchForm = ref<typeof SearchForm | null>(null);

const submitForm = () => searchForm.value?.dynamicSubmit();

const props = defineProps({
	header: { type: String, required: false, default: 'Filter' },
	applyButton: { type: String, required: false, default: 'Apply' },
	cancelButton: { type: String, required: false, default: 'Cancel' },
});

const openModal = () => {
	// On modal open save the current state
	store.dispatch('searchMask/saveCurrentState');
	window.history.replaceState(window.history.state, '', window.location.href.split('#')[0]);
	searchModal.value?.openModal();
};

const handleApply = () => {
	submitForm();
};

const handleClose = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
};

onBeforeMount(() => {
	EventBus.$on('FilterModal:submit', 	submitForm);
});

onBeforeUnmount(() => {
	EventBus.$off('FilterModal:submit', submitForm);
});

defineExpose({ openModal });
</script>

<style scoped>
:deep(.search-form-modal .theme-main-form__footer) {
	display: none;
}

</style>
