<template>
	<transition name="modal">
		<div
			class="modal modal-mask"
		>
			<div class="modal-container">
				<button
					type="button"
					class="modal-close"
					@click="onCloseClick"
				>
					<BaseIcon
						name="close"
						class="modal-close__icon"
					/>
				</button>

				<h2 class="modal-header">
					{{ title }}
				</h2>

				<div class="modal-body">
					<!-- @slot the main text of the body -->
					<slot name="body"></slot>
				</div>

				<div class="modal-footer">
					<RoundedButton
						v-if="leftButton"
						class="modal-footer__button modal-footer__button-left"
						:theme="leftButton.theme"
						@click.prevent="onLeftButtonClick"
					>
						<BaseIcon
							:name="leftButton.icon"
							class="modal-footer__button--icon"
						/>
						{{ leftButton.text }}
					</RoundedButton>
					<RoundedButton
						v-if="rightButton"
						class="modal-footer__button modal-footer__button-right"
						:theme="rightButton.theme"
						@click.prevent="onRightButtonClick"
					>
						<BaseIcon
							:name="rightButton.icon"
							class="modal-footer__button--icon"
						/>
						{{ rightButton.text }}
					</RoundedButton>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface ModalButton {
	text: string;
	icon: string;
	theme: string;
}

interface Props {
	title: string,
	leftButton?: ModalButton | null,
	rightButton?: ModalButton | null,
}

defineProps<Props>();

const emit = defineEmits(['SmallModal:Close', 'SmallModal:LeftButton', 'SmallModal:RightButton']);

const onCloseClick = ($event: Event) => {
	$event.preventDefault();
	emit('SmallModal:Close', $event);
};

const onLeftButtonClick = ($event: Event) => {
	emit('SmallModal:LeftButton', $event);
};

const onRightButtonClick = ($event: Event) => {
	emit('SmallModal:RightButton', $event);
};

</script>

<style lang="scss" scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 0.8rem;
	background-color: $color-black-t5;
	display: flex;
	transition: opacity 0.3s ease;
}

.modal-container {
	position: relative;
	max-width: 81.6rem;
	margin: auto auto;
	padding: 2rem 1.3rem;
	background: transparent linear-gradient(253deg, $color-white 0%, #9BE6EF 88%, $color-primary-l3 100%) 0% 0% no-repeat padding-box;
	border: 0.2rem solid #58e1ec;
	border-radius: 1.3rem;
	box-shadow: 1.2rem 1.2rem 1.6rem $color-black-t2;
	font-family: Helvetica, Arial, sans-serif;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/*  IE specific rules */
		height: 40rem;
	}
}

.modal-header,
.modal-body {
	max-width: 80%;
	margin: 0 auto;
}

.modal-header {
	font-size: $font-large;
	font-weight: $bold-default;
	margin-top: 4rem;
	padding: 0;
	padding-left: 0;
	text-align: left;
}

.modal-body {
	font-family: $font-family;
	font-size: $font-small;
	color: $color-dark-gray;
	font-weight: $bold-default;
	line-height: $line-height-text-small;
}

.modal-footer {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
}

.modal-close {
	position: absolute;
	right: 0.1rem;
	top: 1rem;
	border: none;
	background: none;
	color: $color-primary-l1;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 0.8rem;
	cursor: pointer;

	&:focus,
	&:active {
		outline: none;
	}

	&__icon {
		margin: 0.4rem;
		fill: currentColor;
		width: $font-very-small;
		height: $font-very-small;
	}
}

.modal .modal-footer__button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	padding-left: 1.4rem;
	padding-right: 1.4rem;

	&-right {
		margin-left: 1rem;
	}

	&--icon {
		width: $font-very-small;
		height: $font-very-small;
		fill: currentColor;
		margin-right: 0.7rem;
	}
}

@include media-query-up($breakpoint-mobilelarge) {
	.modal .modal-footer__button {
		font-size: $font-very-small;
		padding-left: 1.6rem;
		padding-right: 1.6rem;

		&--icon {
			margin-right: 0.8rem;
		}
	}
}

@include media-query-up($breakpoint-small) {
	.modal-container {
		padding-top: 5rem;
		padding-bottom: 6rem;
	}

	.modal-header {
		font-size: 4rem;
	}

	.modal-body {
		font-size: $font-large;
	}

	.modal-footer {
		max-width: 95%;
		margin-top: 4rem;
	}

	.modal .modal-footer__button {
		font-size: $font-small;
		padding-left: 5rem;
		padding-right: 5rem;

		&-right {
			margin-left: 2rem;
		}

		&--icon {
			width: $font-small;
			height: $font-small;
		}
	}

	.modal-close {
		font-size: 1.2rem;
		right: 0.6rem;

		&__icon {
			width: $font-large;
			height: $font-large;
		}
	}
}
</style>
