export interface ViewType {
	id: number;
	label: string;
	value: string;
}

const viewTypes: ViewType[] = [
	{ id: 1, label: 'Meerblick', value: 'FullOcean' },
	{ id: 2, label: 'Teilweise Meerblick', value: 'PartialOcean' },
	{ id: 3, label: 'Teilweise Meerblick', value: 'PartialOrFullOcean' },
];

export const viewTypeValues = viewTypes.map((v) => v.value);

export default viewTypes;
