<template>
	<component
		:is="iconComponent"
	>
		<slot></slot>
	</component>
</template>

<!-- If the slot is used, it is also necessary to add the slot tag to the svg, so it gets correctly passed on. -->
<!-- See star-icon.vue for reference. -->

<script lang="ts" setup>
/* eslint-disable global-require */
import { computed } from 'vue';
import ArrowIcon from './svg/arrow-icon.vue';
import ArrowRightIcon from './svg/arrow-right-icon.vue';
import BestPriceWarrantyIcon from './svg/best-price-warranty-icon.vue';
import BriefcaseIcon from './svg/briefcase-icon.vue';
import BoardIcon from './svg/board-icon.vue';
import CalendarIcon from './svg/calendar-icon.vue';
import CancelIcon from './svg/cancel-icon.vue';
import CheckFormFieldIcon from './svg/check-form-field-icon.vue';
import CheckIcon from './svg/check-icon.vue';
import CheckGreenIcon from './svg/check_green-icon.vue';
import CheckRedIcon from './svg/check_red-icon.vue';
import CheckTurquoiseIcon from './svg/check_turquoise-icon.vue';
import ChevronIcon from './svg/chevron-icon.vue';
import ChevronLeftIcon from './svg/chevron-left-icon.vue';
import ChevronRightIcon from './svg/chevron-right-icon.vue';
import ClearSearch from './svg/clear-search.vue';
import ClockIcon from './svg/clock-icon.vue';
import CloseIcon from './svg/close-icon.vue';
import CloseCircleIcon from './svg/close_circle-icon.vue';
import ControlsIcon from './svg/controls-icon.vue';
import CreateIcon from './svg/create-icon.vue';
import DirectFlightIcon from './svg/direct-flight-icon.vue';
import DownloadIcon from './svg/download-icon.vue';
import EnvelopeIcon from './svg/envelope-icon.vue';
import EuroFillIcon from './svg/euro-fill-icon.vue';
import EuroOutlineIcon from './svg/euro-outline-icon.vue';
import ExclamationMarkFillIcon from './svg/exclamation-mark-fill-icon.vue';
import EyeIcon from './svg/eye-icon.vue';
import FilterIcon from './svg/filter-icon.vue';
import FlightStartIcon from './svg/flight-start-icon.vue';
import FullscreenIcon from './svg/fullscreen-icon.vue';
import GroupIcon from './svg/group-icon.vue';
import HalfStarIcon from './svg/half-star-icon.vue';
import HalfSunIcon from './svg/half-sun-icon.vue';
import InfoIcon from './svg/info-icon.vue';
import InfoFillIcon from './svg/info-fill-icon.vue';
import InfoOutlineIcon from './svg/info-outline-icon.vue';
import LockCheckoutIcon from './svg/lock-checkout-icon.vue';
import MarkerIcon from './svg/marker-icon.vue';
import MarkerIcon2 from './svg/marker2.vue';
import MinusIcon from './svg/minus-icon.vue';
import MoveUpIcon from './svg/move-up-icon.vue';
import NewTabIcon from './svg/new-tab-icon.vue';
import NotChecked from './svg/not-checked.vue';
import PackageIcon from './svg/package-icon.vue';
import PhoneIcon from './svg/phone-icon.vue';
import PlaneGoIcon from './svg/plane-go-icon.vue';
import PlaneNew from './svg/plane-new.vue';
import PlaneDirect from './svg/plane-direct.vue';
import PlaneIcon from './svg/plane-icon.vue';
import PlusIcon from './svg/plus-icon.vue';
import PrinterIcon from './svg/printer-icon.vue';
import QuestionCircleIcon from './svg/question-circle-icon.vue';
import ReloadIcon from './svg/reload-icon.vue';
import RoomIcon from './svg/room-icon.vue';
import SearchIcon from './svg/search-icon.vue';
import ShieldIcon from './svg/shield-icon.vue';
import SphereIcon from './svg/sphere-icon.vue';
import StarIcon from './svg/star-icon.vue';
import SunIcon from './svg/sun-icon.vue';
import TemperatureWhiteIcon from './svg/temperature-white-icon.vue';
import TransferIcon from './svg/transfer-icon.vue';
import TrashcanIcon from './svg/trashcan-icon.vue';
import UnserTippIcon from './svg/unser-tipp-icon.vue';
import WavesIcon from './svg/waves-icon.vue';
import Pages from './svg/pages.vue';
import Home from './svg/home.vue';
import Location from './svg/location.vue';
import Store from './svg/store.vue';
import Pencil from './svg/pencil.vue';
import HeartIcon from './svg/heart-icon.vue';
import SortIcon from './svg/sort-icon.vue';
import SortDescIcon from './svg/sort-desc-icon.vue';
import FilterNewIcon from './svg/filter-new-icon.vue';
import MapIcon from './svg/map-icon.vue';
import CrossRounded from './svg/cross-rounded-icon.vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
		validator(value: string) {
			return Object.prototype.hasOwnProperty.call(icons, value);
		}
	}
});

const iconComponent = computed(() => icons[props.name]);

</script>

<script lang="ts">
type Icons = {
	[key: string]: unknown; // type Component throws a compile warning
}

const icons: Icons = {
	arrow: ArrowIcon,
	arrowRight: ArrowRightIcon,
	bestPriceWarranty: BestPriceWarrantyIcon,
	board: BoardIcon,
	briefcase: BriefcaseIcon,
	calendar: CalendarIcon,
	cancel: CancelIcon,
	check: CheckIcon,
	checkGreen: CheckGreenIcon,
	checkRed: CheckRedIcon,
	checkTurquoise: CheckTurquoiseIcon,
	checkFormField: CheckFormFieldIcon,
	chevron: ChevronIcon,
	chevronLeft: ChevronLeftIcon,
	chevronRight: ChevronRightIcon,
	clearSearch: ClearSearch,
	clock: ClockIcon,
	close: CloseIcon,
	closeCircle: CloseCircleIcon,
	controls: ControlsIcon,
	create: CreateIcon,
	directFlight: DirectFlightIcon,
	download: DownloadIcon,
	envelope: EnvelopeIcon,
	euroFill: EuroFillIcon,
	euroOutline: EuroOutlineIcon,
	exclamationMarkFill: ExclamationMarkFillIcon,
	eye: EyeIcon,
	flightStart: FlightStartIcon,
	fullscreen: FullscreenIcon,
	filter: FilterIcon,
	group: GroupIcon,
	halfSun: HalfSunIcon,
	halfStar: HalfStarIcon,
	heart: HeartIcon,
	infoOutline: InfoOutlineIcon,
	infoFill: InfoFillIcon,
	info: InfoIcon,
	lockCheckout: LockCheckoutIcon,
	marker: MarkerIcon,
	marker2: MarkerIcon2,
	minus: MinusIcon,
	moveUp: MoveUpIcon,
	newTab: NewTabIcon,
	notChecked: NotChecked,
	package: PackageIcon,
	printer: PrinterIcon,
	plane: PlaneIcon,
	planeGo: PlaneGoIcon,
	planeNew: PlaneNew,
	planeDirect: PlaneDirect,
	phone: PhoneIcon,
	plus: PlusIcon,
	questionCircle: QuestionCircleIcon,
	reload: ReloadIcon,
	room: RoomIcon,
	shield: ShieldIcon,
	search: SearchIcon,
	sphere: SphereIcon,
	sun: SunIcon,
	star: StarIcon,
	thermometer: TemperatureWhiteIcon,
	transfer: TransferIcon,
	trashcan: TrashcanIcon,
	unserTipp: UnserTippIcon,
	waves: WavesIcon,
	pages: Pages,
	home: Home,
	location: Location,
	store: Store,

	pencil: Pencil,
	sort: SortIcon,
	sortDesc: SortDescIcon,
	filterNew: FilterNewIcon,
	map: MapIcon,
	crossRounded: CrossRounded
};

export default {};

</script>
