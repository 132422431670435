<template>
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.3714 1C21.0953 1 25.7407 5.39142 25.7407 10.8C25.7407 12.3273 25.4035 13.7273 24.7302 15C21.4303 22.1273 15.3704 29 15.3704 29C15.3704 29 9.31039 22.1273 6.01053 15C5.3372 13.7273 5 12.3273 5 10.8C5 5.3904 9.64652 1 15.3693 1H15.3714ZM15.3714 15.6364C18.1994 15.6364 20.4888 13.4727 20.4888 10.8C20.4888 8.12727 18.1994 5.96364 15.3714 5.96364C12.5435 5.96364 10.2541 8.12727 10.2541 10.8C10.2541 13.4727 12.5435 15.6364 15.3714 15.6364Z"
			fill="currentColor"
		/>
	</svg>
</template>
