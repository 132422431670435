import { DateInput, formatDate } from './utils';

/**
 * Adds the given days to todays date at midnight and
 * returns the timestamp as string.
 * Usecase: mobile calendar for selecting. eg. document.querySelector('[data-date="1652652000000"]')
 *
 * @param {number} days
 * @param {string} format 'timestamp' | 'dashed-date'
 * @returns {string} timestamp eg. 1652652000000 or dashed date eg. 2022-04-17
 */
export const addDaysToToday = (days: number, format: 'timestamp' | 'dashed-date' = 'timestamp'): string => {
	const todayAtMidnight = new Date(new Date().setHours(0, 0, 0, 0));

	if (format === 'dashed-date') {
		const offSetDate = todayAtMidnight.setDate(todayAtMidnight.getDate() + days + 1); // +1 is necessary to get the correct date
		return new Date(offSetDate).toISOString().slice(0, 10);
	}

	const offSetDate = todayAtMidnight.setDate(todayAtMidnight.getDate() + days);
	return offSetDate.toString();
};

// TODO add other date related functions from utils.ts

export const calculateDaysUntilTime = (inputTime: number): number => {
	const dayInMiliseconds = (24 * 60 * 60 * 1000);
	const timeDifference: number = inputTime - Date.now() + dayInMiliseconds;
	return Math.floor(timeDifference / dayInMiliseconds);
};

export function formatDateWeekdayPoint(dateParam: DateInput) {
	const weekday = formatDate(dateParam, { weekday: 'short' });
	const date = formatDate(dateParam, { month: '2-digit', day: '2-digit' });

	return `${weekday}. ${date}`;
}

export function convertNumberToTime(value: number | undefined | null) {
	if (value === null || value === undefined) return '';
	if (value < 10) {
		return `0${value}:00`;
	}
	if (value === 24) {
		return '23:59';
	}
	return `${value}:00`;
}
export function convertToDateIfNumber(date: Date) {
	return Number(date) ? dashedDate(new Date(Number(date))) : date;
}

export function dateParts(source: string | number | Date): any[] {
	const date = new Date(source);
	const [y, m, d] = [date.getFullYear(), date.getMonth(), date.getDate()];
	const month = `0${m + 1}`.slice(-2);
	const day = `0${d}`.slice(-2);

	return [y, month, day];
};

export function dashedDate(source: string | number | Date): string {
	const parts = dateParts(source);
	return parts.join('-');
};
