<template>
	<div
		v-if="mapsKey"
		class="map-preview-and-modal"
	>
		<dialog
			ref="mapModalDiv"
			class="map-preview-and-modal__modal"
		>
			<MapWithOffers
				v-if="modalOpened && userCentrics.consentGiven"
				:id="title"
				ref="mapWithOffers"
				:lat="lat"
				:lng="lng"
				:northwest="northwest"
				:southeast="southeast"
				:hotel-request-body="hotelRequestBody"
				:is-hotel-page="isHotelPage"
				:hotel-to-show-iff-code
				:hotel-to-show
			/>
			<button
				class="map-preview-and-modal__close-btn"
				aria-label="Vollbild-Karte schließen"
				@click.prevent="modal?.closeModal()"
			>
				<BaseIcon
					name="close"
					class="map-preview-and-modal__close-icon"
				/>
			</button>
		</dialog>
		<div
			v-if="userCentrics.consentGiven"
			id="map-usercentrics"
			class="map-preview-and-modal__preview"
			@click.prevent="loadFullscreen()"
		>
			<button
				class="map-preview-and-modal__fullscreen-btn"
				aria-label="Vollbild-Karte anzeigen"
			>
				<BaseIcon
					name="fullscreen"
					class="map-preview-and-modal__fullscreen-icon"
				/>
			</button>
			<picture
				id="static-map-container"
				class="map-preview-and-modal__static-map"
			>
				<source
					v-if="staticMaps.staticgmap_992"
					media="(min-width: 544px)"
					:data-srcset="staticMaps.staticgmap_992"
				/>
				<source
					v-if="staticMaps.staticgmap_544"
					:media="staticMaps.staticgmap_420 ? '(min-width: 420px)' : ''"
					:data-srcset="staticMaps.staticgmap_544"
				/>
				<source
					v-if="staticMaps.staticgmap_420"
					:data-srcset="staticMaps.staticgmap_420"
				/>
				<img
					ga-data-event_category="UX"
					ga-data-event_action="Map"
					:ga-data-event_label="gaEventLabel"
					:data-src="staticMaps.staticgmap_544 || staticMaps.staticgmap_420"
					src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
					:alt="`Google Map von ${title}`"
					class="rpb_lazy map-preview-and-modal__static-map-img"
				/>
			</picture>
		</div>
		<div
			v-else
			class="map-preview-and-modal__place-holder"
		>
			<div
				id="map-usercentrics"
				class="map-preview-and-modal__place-holder"
			>
				<Loading class="map-preview-and-modal__loading" />
				<!-- User Centrics replace Container-->
			</div>
		</div>
		<span
			v-if="showNote"
			class="map-preview-and-modal__note"
		>(Positionsangaben ohne Gewähr)</span>
	</div>
</template>

<script lang="ts" setup>
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import {
	ref, onMounted, onUnmounted, Ref, reactive, onBeforeMount,
} from 'vue';
import { StaticMaps, MarkerData } from '@interfaces/google-maps';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import MapWithOffers from '@lmt-rpb/MapWithOffers/MapWithOffers.vue';
import Modal from '@/js/modules/modal';
import UserCentrics from '@/js/modules/UserCentrics';
import { ApiDataTypes } from '@/components/common/types';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';

interface Props {
	title: string,
	lat: number,
	lng: number,
	zoom?: number,
	staticMaps: StaticMaps,
	mapsKey: string,
	marker?: boolean,
	customMarkerData?: Array<MarkerData>,
	gaEventLabel?: string,
	showNote?: boolean,
	northwest: {lat: number, lng: number} | null,
	southeast: {lat: number, lng: number} | null,
	hotelRequestBody: ApiDataTypes
	hotelToShow?: ItemType | null
	hotelToShowIffCode?: number
	isHotelPage?: boolean
}

withDefaults(defineProps<Props>(), {
	zoom: 11,
	marker: true,
	customMarkerData: () => [],
	gaEventLabel: 'Hotelseite',
	showNote: true,
	hotelToShow: undefined,
	hotelToShowIffCode: undefined,
	isHotelPage: false
});

const mapModalDiv = ref<HTMLDialogElement>();

const modal: Ref<Modal| null> = ref(null);

const userCentrics = reactive(new UserCentrics('Google Maps'));

const modalOpened = ref(false);

const mapWithOffers: Ref<InstanceType<typeof MapWithOffers> | null> = ref(null);

const loadFullscreen = async() => {
	modal.value?.openModal();
	if (modalOpened.value) {
		mapWithOffers.value?.openDefaultInfoWindow();
	}
	modalOpened.value = true;
};

function handleUserCentrics() {
	userCentrics.init();
	userCentrics.blockElement({ S1pcEj_jZX: '#map-usercentrics' });
}

onBeforeMount(() => {
	handleUserCentrics();
});

onMounted(() => {
	if (mapModalDiv.value) {
		modal.value = new Modal(mapModalDiv.value);
	}
});

onUnmounted(() => {
	modal.value?.closeModal();
});

</script>

<style lang="scss" scoped>
.map-preview-and-modal {
	&__place-holder {
		height: 26.8rem;
	}

	&__fullscreen-btn {
		z-index: 2;
		border: none;
		user-select: none;
		height: 4rem;
		width: 4rem;
		overflow: hidden;
		background: none $color-white;
		padding: 0;
		text-transform: none;
		appearance: none;
		position: absolute;
		box-shadow: #0000004d 0 0.1rem 0.4rem -0.1rem;
		border-radius: 0.2rem;
		top: 0;
		right: 1rem;
		margin: 2.6rem 1rem;
	}

	&__preview {
		position: relative;
		overflow: hidden;
		height: 100%;
		fill: $color-dark-gray;

		&:hover {
			fill: $color-black;
		}
	}

	&__fullscreen-icon {
		width: 3rem;
		height: 100%;
	}

	&__loading {
		margin: auto;
		height: 100% !important;
	}

	&__static-map {
		height: 100%;
		width: 100%;
	}

	&__static-map-img {
		height: 100%;
		object-fit: contain;
		width: 100%;
		opacity: 1;
		z-index: 1;
		position: sticky;
		cursor: pointer;
	}

	&__modal {
		padding: 0;
		margin: 0;
		border: none;
		max-width: none;
		max-height: none;
		height: 100vh;
		width: 100vw;
	}

	&__close-btn {
		background-color: $color-white;
		border: none;
		border-radius: 0.2rem;
		height: 4rem;
		width: 4rem;
		margin-top: 0.6rem;
		margin-left: 0.1rem;
		position: absolute;
		top: 0.4rem;
		right: 1rem;
		box-shadow: #0000004d 0 0.1rem 0.4rem -0.1rem;

		&:hover {
			path {
				fill: $color-black;
			}
		}
	}

	&__close-icon {
		margin-top: 0.4rem;
		margin-left: 0;
		width: 1.8rem;
		fill: $color-dark-gray;

		&:hover {
			fill: $color-black;
		}
	}

	&__note {
		padding-left: 0.5rem;
		padding-bottom: 3rem;
		font-size: 1.2rem;
		line-height: 2.3;
		left: 0.7rem;
		position: relative;
	}

	@media (min-width: $breakpoint-verylarge) {
		&__note {
			padding: 0;
		}
	}
}
</style>
