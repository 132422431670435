<template>
	<div class="feature-list">
		<div
			v-if="!(location === 'checkoutSummary') && !isLongDate && isNumber(duration)"
			class="feature-list__duration"
		>
			{{ pluralize(duration ?? 0, 'Tag', 'Tage') }}
		</div>
		<div
			v-else-if="isLongDate"
			class="feature-list__duration"
		>
			{{ duration }}
		</div>
		<ul v-if="detailList">
			<OfferFeature
				v-for="(feature, index) in detailList"
				:key="index"
				:feature="feature"
				:feature-bold="isFirstFeatureBold && index === 0"
				:location="location"
				@mouseover="toggleTooltip(feature.text, true)"
				@mouseleave="toggleTooltip(feature.text, false)"
			>
				<span
					v-if="feature.text !== '100 € Gutschein'"
					class="offer-feature__text"
				>{{ feature.text }}</span>
				<span
					v-if="feature.text == '100 € Gutschein'"
					class="offer-feature__text"
				>{{ formatPrice(100, 'EUR') }} Gutschein
					<InfoTooltip
						class="offer-feature__tooltip"
						:show-tooltip-parent="showTooltip"
					>
						Mit Ihrer Reisebuchung erhalten Sie als Dankeschön einen {{ formatPrice(100, 'EUR') }} Gutschein für Ihre nächste Reise.
					</InfoTooltip>
				</span>
			</OfferFeature>
		</ul>
		<div
			v-for="(feature, index) in offerFeaturesArray"
			:key="index"
		>
			<FlexFeature :feature="feature.feature" />
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	computed, onMounted, ref, watch,
} from 'vue';
import { OfferData } from '@/interfaces/checkout/offer-data';
import { useStore } from '@/components/common/store';
import viewTypes, { ViewType } from '@/js/data/view-types';
import { boardTypes } from '@/js/data/index';
import OfferFeature from '@lmt-rpb/OfferFeature/OfferFeature.vue';
import FlexFeature from '@lmt-rpb/FlexFeature/FlexFeature.vue';
import { convertFeaturesIntoArray, formatDate, pluralize } from '@/js/utils/utils';
import { MergedOfferItemData } from '@/interfaces/offer';
import { ShoppingCart } from '@/interfaces/checkout/shopping-cart';
import { NDASH, PageLocation } from '@global-js/constants';
import { isNumber } from '@vueuse/core';
import { formatPrice } from '@/js/utils/priceUtils';
import { OfferType } from '@/interfaces/components/featureListData';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';
import { Feature } from '../OfferFeature/offer-feature';

type Props = {
	offer: OfferType
	isFirstFeatureBold?: boolean
	location?: PageLocation
	isLongDate?: boolean
}

const props = defineProps<Props>();

const store = useStore();

const duration = ref<number | string>(0);
const seaView = ref<string>('');
const offerData = ref<OfferType>({});
const offerFeaturesArray = ref<Feature[]>([]);
const boardType = ref<string>('');
const showTooltip = ref(false);
const flexStornoChecked = computed(() => store.state.checkout.FlexStornoChecked);
const isCheckoutLocation = ['checkoutInfoBox', 'checkoutSummary', 'thankyouPage'].includes(props.location ?? '');

type DetailList = {
	id: number
	checked: boolean
	description?: string
	text: string
}[]

function computeDuration() {
	let durationValue = 0;
	if (props.isLongDate && isOfferData(props.offer)) {
		const startDate = new Date((props.offer as OfferData).Offer.StartDate);
		const endDate = new Date((props.offer as OfferData).Offer.EndDate);
		if (!startDate || !endDate) {
			return NDASH;
		}
		const durationLong = props.offer.Offer.StayDuration ? ` (${pluralize(props.offer.Offer.StayDuration, 'Tag', 'Tage')})` : '';
		return `${formatDateLong(startDate)} ${NDASH} ${formatDateLong(endDate)}${durationLong}`;
	}

	if (isOfferData(props.offer)) durationValue = props.offer.Offer.StayDuration || props.offer.Offer.OvernightStays || 0;
	else if (isMergedOfferItemData(props.offer)) durationValue = props.offer.duration || props.offer.StayDuration || 0;
	else if (isShoppingCart(props.offer)) durationValue = props.offer.Travel.Duration || 0;
	return durationValue;
}

function computeSeaView() {
	let seaViewValue = '';
	if (isOfferData(props.offer)) seaViewValue = props.offer.Offer.Room.View || '';
	else if (isMergedOfferItemData(props.offer)) seaViewValue = props.offer.Room.View || '';
	else if (isShoppingCart(props.offer)) seaViewValue = props.offer.Travel.Hotel.View || '';
	return seaViewValue;
}

function computeOffer() {
	let offerValue = {};
	if (isOfferData(props.offer)) offerValue = props.offer.Offer as unknown as OfferData;
	else if (isShoppingCart(props.offer)) offerValue = props.offer as ShoppingCart;
	else if (isMergedOfferItemData(props.offer)) offerValue = props.offer as MergedOfferItemData;
	return offerValue;
}

function computeOfferFeaturesArray(flexStornoSelected?: boolean) {
	let features = convertFeaturesIntoArray(offerData.value.OfferFeatures, store.state.checkout.FlexStorno);

	if (flexStornoSelected) {
		features = features.filter((feature) => feature.title !== 'Flexible Stornierung zubuchbar');
	}
	return features ? features.map((feature, index) => ({
		id: index,
		checked: true,
		text: feature.title,
		feature: { title: feature.title, description: feature.description }
	})) : [];
}

function computeBoardType() {
	return boardTypes.find((type) => {
		const mealType = isShoppingCart(props.offer) ? props.offer.Travel.Hotel.MealType : offerData.value.BoardType;
		return type.value.toLowerCase() === mealType.toLowerCase();
	})?.label || '';
}

function getCommonList(RoomName: string, OfferIncludes: string[], OfferForLimitedMobility: boolean, TransferInfos?: string[]): DetailList {
	return [
		{ id: -1, checked: true, text: `1x ${RoomName}` },
		{ id: 20, checked: true, text: boardType.value },
		{ id: 30, checked: OfferIncludes?.includes('transfer') || TransferInfos?.includes('TransferIncluded') || false, text: (OfferIncludes?.includes('transfer') || TransferInfos?.includes('TransferIncluded')) ? 'Inkl. Transfer vor Ort' : 'Ohne Transfer vor Ort' },
		...(OfferIncludes?.includes('railandfly') ? [{ id: 40, checked: true, text: 'Zug zum Flug (innerh. Deutschlands)' }] : []),
		...(isCheckoutLocation ? [{ id: 35, checked: OfferForLimitedMobility, text: OfferForLimitedMobility ? 'Geeignet für Personen mit eingeschränkter Mobilität.' : 'Nicht geeignet für Personen mit eingeschränkter Mobilität.' }] : []),
	];
}

const detailList = computed<DetailList>(() => {
	const flexStorno = flexStornoChecked.value;
	const isNotUnverifiedOffer = props.location !== 'unverifiedOffer';
	const isCheckoutSummary = props.location === 'checkoutSummary';
	const isThankYouPage = props.location === 'thankyouPage';

	let list: DetailList = [];

	if (isShoppingCart(props.offer)) {
		const offer = props.offer;
		if (offer && offer.Travel && offer.Travel.Hotel && offer.Travel.Hotel.RoomName) {
			const {
				Hotel: { RoomName }, OfferIncludes = [], OfferForLimitedMobility
			} = offer.Travel;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility);
		}
	} else {
		const offer = offerData.value;
		if (offer && offer.Room && offer.Room.Name) {
			const {
				Room: { Name: RoomName }, OfferIncludes, OfferForLimitedMobility, TransferInfos
			} = offer;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility, TransferInfos);
		}
	}

	if (isNotUnverifiedOffer) {
		list.push({ id: 50, checked: true, text: '100 € Gutschein' });
	}

	if (isCheckoutSummary && isOfferData(props.offer) && props.offer.Offer.OutboundFlightSegments) {
		const { OutboundFlightSegments, InboundFlightSegments } = props.offer.Offer;
		list.push({ id: -3, checked: true, text: 'inkl. Flug ab ' + OutboundFlightSegments[0].Departure.Airport });
		const date = duration.value === 1 ? '1 Tag' : duration.value + ' Tage';
		const dateDeparture = formatDateLong(OutboundFlightSegments[0].Departure.Date);
		const dateInbound = formatDateLong(InboundFlightSegments[InboundFlightSegments.length - 1].Arrival.Date);
		list.push({ id: -2, checked: true, text: `${date}, ${dateDeparture} - ${dateInbound}` });
	}

	viewTypes.forEach((viewType: ViewType) => {
		if (seaView.value === viewType.value) {
			list.push({ id: 11, checked: true, text: viewType.label });
		}
	});

	if ((isThankYouPage && props.offer.Pricing?.OptionalFlexRate?.Amount) || flexStorno) {
		list.push({ id: 60, checked: true, text: 'Flexible Stornierung' });
	}

	if (!flexStorno && !isThankYouPage) {
		list = list.filter((item) => item.id !== 60);
	}

	return list.sort((a, b) => {
		if (a.checked === b.checked) {
			return a.id - b.id;
		}
		return b.checked ? 1 : -1;
	});
});

const toggleTooltip = (text: string, state: boolean) => {
	if (text === '100 € Gutschein') {
		showTooltip.value = state;
	}
};

const isOfferData = (data?: OfferType): data is OfferData => (data as OfferData).Offer !== undefined;
const isShoppingCart = (data?: OfferType): data is ShoppingCart => (data as ShoppingCart).ShoppingCartID !== undefined;
const isMergedOfferItemData = (data?: OfferType): data is MergedOfferItemData => (data as MergedOfferItemData).OfferID !== undefined;

const formatDateLong = (date: string | Date | undefined) => (date ? formatDate(date, {
	weekday: 'short', month: '2-digit', day: '2-digit', year: '2-digit'
}).replace(',', '') : '.');

onMounted(() => {
	duration.value = computeDuration();
	seaView.value = computeSeaView();
	offerData.value = computeOffer();
	offerFeaturesArray.value = computeOfferFeaturesArray();
	boardType.value = computeBoardType();
});

watch(flexStornoChecked, (value) => {
	offerFeaturesArray.value = computeOfferFeaturesArray(value);
});
</script>

<style lang="scss" scoped>
.feature-list {
	list-style: none;
	margin: 0;
	padding: 0;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li:nth-child(1) {
			display: inline-flex;
			overflow-wrap: anywhere;
			line-break: anywhere;
		}
	}

	&__duration {
		font-weight: $bold-default;
		color: $color-text;
		font-size: $font-almost-large;
	}

	.offer-feature__tooltip {
		margin-left: 0.5rem;
	}

	.offer-feature__text {
		display: inline-flex;
	}

}
</style>
