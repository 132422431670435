import {
	isBoolean, isNumber, isObject, isString,
} from '@vueuse/core';
import { Primitive } from 'zod';

export function flatMap<T = unknown>(list: {[x: string]: unknown}[]): T {
	return list.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as unknown as T;
}

export function hasValue(o: string | number | boolean | undefined | unknown): boolean {
	if (typeof o === 'string') {
		return o.trim().length > 0 && isString(o);
	}
	if (typeof o === 'number') {
		return !Number.isNaN(o) && isNumber(o);
	}
	if (typeof o === 'object') {
		return JSON.stringify(o) !== '{}' && isObject(o);
	}
	if (typeof o === 'boolean') {
		return isBoolean(o);
	}
	return false;
}

export function mergeMaps<K = string, V = unknown>(...maps: Array<Map<K, V>>): Map<K, V> {
	return new Map(
		maps.flatMap((m) => Array.from(m.entries()))
	);
}

export function filterObjectArray<T extends Record<string, Primitive>>(item: T, fieldsToFilterBy: Array<keyof T>, filterExpression: string): boolean {
	return fieldsToFilterBy.some(
		(field) => item[field]?.toString().toLowerCase().includes(filterExpression.toLowerCase())
	);
}

export function filterDuplicates<T>(array: T[]): T[] {
	//  Set ist nur unique für primitive Datentypen und die Reihenfolge NICHT deterministisch
	return Array.from(new Set(array));
}
