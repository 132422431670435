<template>
	<SearchModal
		ref="searchModal"
		:header="props.header"
		:apply-button="props.applyButton"
		:cancel-button="props.cancelButton"
		@SearchModal:applyChanges="handleApply"
		@SearchModal:close="handleClose"
	>
		<FilterMobile :is-hotel-page="props.isHotelPage"></FilterMobile>
	</SearchModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import FilterMobile from '@lmt-rpb/FilterMobile/FilterMobile.vue';
import store from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';

const searchModal = ref<typeof SearchModal | null>(null);

const props = defineProps({
	isHotelPage: { type: Boolean, required: false, default: false },
	header: { type: String, required: false, default: 'Filter' },
	applyButton: { type: String, required: false, default: 'Apply' },
	cancelButton: { type: String, required: false, default: 'Cancel' },
});

const openModal = () => {
	store.dispatch('searchMask/saveCurrentState');
	window.history.replaceState(window.history.state, '', window.location.href.split('#')[0]);
	searchModal.value?.openModal();
};

const handleApply = () => {
	EventBus.$emit('FilterModal:submit');
};

const handleClose = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
};

defineExpose({ openModal });
</script>
